import { Delete, EditOutlined } from "@mui/icons-material";
import React, { useCallback, useMemo } from "react";
import toast from "react-hot-toast";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import ProductPanelComponent from "../../../../components/product-panel/product-panel.component";
import StockSelectComponent from "../../../../components/stock-select/stock-select.component";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableActionsPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { PanelType } from "../../../../enums/panel-type.enum";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import { useStore } from "../../../../hooks/store.hook";
import { BulkStockUpdateType, ProductType, StockLinesType } from "../../../../types/product.type";
import { bulkStockUpdate, updateStockLine } from "../../../../utils/requests";
import { ProductsPageStyled } from "./products.page.styled";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import StockLines from "../../../../components/stock-lines/stock-lines.component";
import { DialogResponseTypeEnum } from "../../../../enums/dialog-response-type.enum";
import { CurrencyEnum } from "../../../../enums/currency.enum";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import moment from 'moment';
import { isAdmin } from "../../../../utils/utils";
import ProductsExportPanelComponent from "../../../../components/products-export.panel/products-export.panel.component";




const ProductsPage = () => {

    const uiStore = useStore('uiStore');
    const tableStore = useStore('tableStore');
    const userStore = useStore('userStore');

    const buttons = [
        {
            text: "Export",
            fOnClick: () => {
                uiStore.openPanel({
                    key: PanelType.ExportProducts,
                    component: <ProductsExportPanelComponent />,
                    title: 'Export raport produse'
                })
            }
        },
        {
            text: "Creeaza un produs",
            fOnClick: () => {
                uiStore.openPanel({
                    key: PanelType.AddProduct,
                    panelWidth: '650px',
                    component: <ProductPanelComponent />
                });
            }
        },
    ]

    const actionItems: ActionItemPropsType[] = [
        {
            text: "Editeaza",
            icon: <EditOutlined/>,
            color: "black",
            fOnClick: (row: any) => {
                uiStore.openPanel({
                    key: PanelType.AddProduct,
                    panelWidth: '650px',
                    component: <ProductPanelComponent productId={row.id} />,
                });
            }
        },
        {
            text: "Adauga Stoc",
            icon: <AddBoxOutlinedIcon/>,
            color: "black",
            fOnClick: (row: any) => {
                uiStore.openPanel({
                    key: PanelType.ProductStockLine,
                    component: <StockLines productId={row.id} />,
                });
            }
        },
    ]

    const customRenderer: CustomRendererPropsType = {
        sku: (row: ProductType) => { 
            return <div style={{color: Number(row.stockTotal) <= 0 ? 'red': 'black'}}>{row.sku}</div>
        },
        name: (row: ProductType) => { 
            return <div style={{color: Number(row.stockTotal) <= 0 ? 'red': 'black'}}>{row.name}</div>
        },
        stockTotal: (row: ProductType) => { 
            return <div style={{color: Number(row.stockTotal) <= 0 ? 'red': 'black'}}>{row.stockTotal}</div>
        },
        category: (row: ProductType) => { 
            return <div style={{color: Number(row.stockTotal) <= 0 ? 'red': 'black'}}>{row.category}</div>
        },
        price: (row: ProductType) =>{
            return <div style={{color: Number(row.stockTotal) <= 0 ? 'red': 'black'}}>{row.price} {CurrencyEnum.RON}</div>
        }
            
    };

    const stockLinesAction: ActionItemPropsType[] = useMemo(
        () => {
            return [
                {
                    text: "Editeaza",
                    icon: <EditOutlined/>,
                    color: "black",
                    async fOnClick(row: StockLinesType) {
                        if(row.productId !== undefined)
                            uiStore.openPanel({
                                key: PanelType.EditUser,
                                component: <StockLines productId={row.productId} stockLine={row}  />,
                            });
                    }
                },
                {
                    text: "Sterge",
                    icon: <Delete />,
                    color: "black",
                    async fOnClick(row: StockLinesType) {
                        const response = await uiStore.openDialog({
                            title: "Stergi stoc...",
                            message: "Esti sigur ca vrei sa continui?",
                        })    
                        if(response.value === DialogResponseTypeEnum.Confirm) {
                            
                            try {
                                if(row.productId)
                                    await updateStockLine(row.productId, row, true)
                                toast.success('Linia de stoc a fost stearsa cu succes');
                                tableStore.updateTable(TableTypeEnum.Products)
                            }
                            catch (e: any) {
                                toast.error(e.message)
                            }
                        }
                    }
                },
            ]
        },
        [tableStore, uiStore]
    )


    const onBulkStockChangeConfirm = useCallback(
        (updatedRows: ProductType[]) => {
           
            /** map rows to id&stock objects */
            let stockLinesUpdated = updatedRows.map( m => m.stockLines.map( n => {
                if(tableStore.oldUpdatedData[m.id].indexOf(n.id) >= 0)
                    return {
                        productId: m.id,
                        id: n.id,
                        description: n.description,
                        price: n.price,
                        stock: n.stock
                    }
                return undefined
                    
            }))
            .reduce((prev, curr) => [...prev,...curr], [])
            .filter( el => el !== undefined)

            const mappedRows: BulkStockUpdateType = {
                items: stockLinesUpdated.map( n => {
                    return { 
                        id: n ? n.productId : '',
                        stockLines:{
                            id: n ? n.id : '',
                            description: n ? n.description : '',
                            price: n ? n.price : '',
                            stock: n ? n.stock : ''
                        }}
                })
            }
            
            toast.promise(bulkStockUpdate(mappedRows), {
                loading: 'Se realizeaza modificarile',
                success: () => {
                    tableStore.updateTable(TableTypeEnum.Products);
                    tableStore.resetOldUpdatedData()
                    uiStore.dismissPersistentToast()
                    return 'Modificarile s-au realizat cu succes'
                },
                error: (e: any) => e.message
            })
        },
        [tableStore, uiStore]
    )
   
    const renderExpandable = useCallback (
        (row: ProductType, tableActions: TableActionsPropsType) => {
            const tableData: TableDataPropsType =  {
                        data: row.stockLines.map( m => ({
                            productId: row.id,
                            id: m.id,
                            description: m.description,
                            price: m.price,
                            stock: m.stock,
                            createdAt: m.createdAt,    
                        })),
                        headers: [
                            {
                                id:'description',
                                label: 'Descriere',
                                alignment: 'left',
                                sortable: false,
                                headerType: HeaderTypeEnum.String
                            },
                            {
                                id:'createdAt',
                                label: 'Data adaugare',
                                alignment: 'left',
                                sortable: false,
                                headerType: HeaderTypeEnum.Date
                            },
                            {
                                id:'stock',
                                label: 'Stoc',
                                alignment: 'center',
                                sortable: false,
                                headerType: HeaderTypeEnum.String
                            },
                            {
                                id:'price',
                                label: 'Pret achizitie',
                                alignment: 'right',
                                sortable: false,
                                headerType: HeaderTypeEnum.String
                            },
                        ]
            }
            const customRender: CustomRendererPropsType = {
                stock: (stockItemRow: StockLinesType, tableAction: TableActionsPropsType) => {
                    return (
                        <StockSelectComponent
                            size="small"
                            variant="standard"
                            value={(stockItemRow.stock ?? 0).toString()}
                            maxWidth='6.25rem'
                            onTextChange={newValue => 
                                tableActions.updateRow(row.id, {
                                    ...row,
                                    stockLines: row.stockLines.map( m => {
                                        if (m.id === stockItemRow.id){
                                            if(tableStore.oldUpdatedData[row.id] === undefined)
                                                tableStore.pushOldUpdatedData({rowId: row.id, stockLineId: stockItemRow.id})
                                            if(tableStore.oldUpdatedData[row.id].indexOf(stockItemRow.id) < 0 )
                                                tableStore.pushOldUpdatedData({rowId: row.id, stockLineId: stockItemRow.id})
                                            return {...m, stock: +newValue}
                                        }
                                        return m
                                    })
                                })
                            }
                        />
                    )
                },
                createdAt: (row: StockLinesType) => {
                    return (
                        <>
                            {
                                row.createdAt ? 
                                moment(new Date(row.createdAt)).format('DD.MM.YYYY')
                                : 
                                ""
                            }
                        </>
                    )
                },
                price: (row: StockLinesType) => `${row.price} ${CurrencyEnum.RON}`
            }
    
            return (
                    <TableComponent 
                        tableKey={TableTypeEnum.ProductsLineItems} 
                        tableData={tableData}
                        viewType="read-only"
                        withoutSelect
                        withoutSearchBar
                        withoutDenseSwitch
                        withoutPersist
                        denseByDefault
                        actionItems={stockLinesAction}
                        customRenderer={customRender}
                    />
            )
        },
        [stockLinesAction, tableStore]

    )

    /** define the return statement bellow */
    return (
        <ProductsPageStyled>
            <PageTemplateComponent title="Produse" buttons={buttons}>
                <TableComponent
                    tableKey={TableTypeEnum.Products} 
                    url="/product" 
                    searchBarPlaceholder="Cauta dupa nume sau SKU" 
                    actionItems={isAdmin(userStore.user?.roles, actionItems)}
                    withoutSelect
                    customRenderer={customRenderer}
                    expandableRenderer={isAdmin(userStore.user?.roles, renderExpandable)}
                    onUpdateConfirm={isAdmin(userStore.user?.roles, onBulkStockChangeConfirm)}
                />
            </PageTemplateComponent>
        </ProductsPageStyled>
    )
 

}

export default ProductsPage;