import { LinearProgress } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useStore } from "../../hooks/store.hook";
import { downloadProductsExportTemplate, getTimeForExportTemplate } from "../../utils/requests";
import ButtonComponent from "../button/button.component";
import OrderHistoryHeaderComponent from "../order-history-header/order-history-header.component";
import ProductsExportPanelComponentStyled from "./products-export.panel.component.styled";

export type ProductsType = {
    startDate?: Date,
    endDate?: Date,
}

const ProductsExportPanelComponent = () => {

    const uiStore = useStore('uiStore');
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [history, setHistory] = useState<ProductsType>();
    const [changedDates, setChangeDates] = useState<ProductsType>()

    const onDownloadTemplate = useCallback(
        (startDate, endDate) => {
            setIsDownloading(() => true);
            downloadProductsExportTemplate(startDate, endDate)
                .catch((e: any) => {
                    toast.error(e.message);
                })
                .finally(() => {
                    setIsDownloading(() => false)
                })
        },
        []
    )

    const fetchHistory = useCallback(
        ({startDate, endDate}: { startDate?: Date, endDate?: Date }) => {
            setIsLoading(() => true)
            getTimeForExportTemplate(startDate, endDate)
                .then((time: ProductsType) => {
                    setHistory(() => time);
                })
                .catch((e: any) => {
                    toast.error(e.message);
                })
                .finally(() => {
                    setIsLoading(() => false)
                })
        },
        []
    )

    const onDatesChange = useCallback(
        (newDates: { startDate?: Date, endDate?: Date }) => {
            setChangeDates(newDates)
        },
        []
    )

    useEffect(
        () => {
            fetchHistory({});
        },
        [fetchHistory]
    )

    useEffect(
        () => {
            uiStore.updatePanel({
                subtitleComponent: (!history) ? undefined : () => (
                    <OrderHistoryHeaderComponent
                        minDate={history?.startDate ?? new Date()}
                        maxDate={history?.endDate ?? new Date()}
                        onDatesChangeCB={onDatesChange}
                    />
                )
            })
        },
        [history, onDatesChange, uiStore]
    )

    /** define the return statement bellow */
    return (
        <ProductsExportPanelComponentStyled>
            {
                isLoading ? 
                <LinearProgress style={{borderRadius: '10px'}} />
                : 
                <div className="button-container" style={{display: 'flex', justifyContent: 'center'}}>
                    <ButtonComponent onClick={() => onDownloadTemplate(changedDates?.startDate ?? history?.startDate, changedDates?.endDate ?? history?.endDate)} isLoading={isDownloading}>
                        Descarca Raport Produse
                    </ButtonComponent>
                </div>
            }

            
        </ProductsExportPanelComponentStyled>
    )

}

export default ProductsExportPanelComponent;