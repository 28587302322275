import styled from "styled-components";
import { themeVariables } from "../../theme/variables";


export type TextFieldComponentStyledPropsType = {
    fullWidth?: boolean;
}

const TextFieldComponentStyled = styled.div<TextFieldComponentStyledPropsType>`

    width: ${props => props.fullWidth ? '100%' : 'initial'};

    .MuiOutlinedInput-root {
        border-radius: 10px;

        input {
            font-family: 'Poppins', sans-serif;
        }
    }

    label {
        font-family: 'Poppins', sans-serif;
    }

    label.Mui-focused {
        color: ${() => themeVariables.colors.primary};
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: ${() => themeVariables.colors.primary};
    }

`;

export default TextFieldComponentStyled