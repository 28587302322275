import styled from "styled-components";
export const ProductsPageStyled = styled.div`


`;

export const RenderStockLinesTable = styled.div`

    .MuiTableCell-root {
        padding: 0px !important;
    }

    .jqCKbJ {
        width: 85%;
    }

`