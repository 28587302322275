import styled from 'styled-components';
import { themeVariables } from '../../theme/variables';

export type FormSectionComponentStyledPropsType = {
    variant?: 'normal' | 'panel'
}

const FormSectionComponentStyled = styled.div<FormSectionComponentStyledPropsType>`

    margin-bottom: ${props => props.variant !== 'normal' ? '0.2rem' : '2.5rem'};

    .form-section-paper {
        padding: ${props => props.variant === 'panel' ? 0 : '1.5rem 3rem' } ;
        border-radius: 20px;
        box-shadow: ${props => props.variant === 'panel' ? 'none' : 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;'};
    }

    .section-header {
        color: gray;
    }

    .section-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.2rem;

        .title {
            font-weight: ${themeVariables.fontWeights.semibold};
            font-size: ${themeVariables.fontSizes.smaller};
            text-transform: uppercase;
        }
    }

    .section-body {
        margin-top: 1.5rem;
        margin-bottom: ${props => props.variant === 'normal' ? '0' : '2.5rem'};
    }

`;

export default FormSectionComponentStyled;