import styled from "styled-components";

const OrderDetailsHeaderComponentStyled = styled.div`
    
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-section {
        display: flex;
        align-items: center;
    }

`;

export default OrderDetailsHeaderComponentStyled;