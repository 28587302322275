import { Container } from "@mui/material";
import React, { useMemo } from "react";
import { HeaderTypeEnum } from "../../enums/header-type.enum";
import { TableTypeEnum } from "../../enums/table-type.enum";
import { shortenString } from "../../utils/utils";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../table/table.component";
import InssuficientStockErrorComponentStyled from "./inssuficient-stock-error.component.styled";

type InssuficientStockLogItemType = {
    sku: string;
    name: string;
    neededQuantity: number;
    availableQuantity: number;
    deficit: number;
}

export type InssuficientStockErrorComponentPropsType = {
    data: InssuficientStockLogItemType[]
}

const InssuficientStockErrorComponent = ({
    data
}: InssuficientStockErrorComponentPropsType) => {

    const parsedData = useMemo(
        (): (InssuficientStockLogItemType & { index: number })[] => {
            return data.map((item, index) => ({
                ...item,
                index: index+1
            }))
        },
        [data]
    )

    const tableData: TableDataPropsType = useMemo(
        () => {
            return {
                data: parsedData,
                headers: [
                    {
                        id: 'index',
                        alignment: 'center',
                        headerType: HeaderTypeEnum.String,
                        sortable: false,
                        label: '#'
                    },
                    {
                        id: 'name',
                        alignment: 'left',
                        headerType: HeaderTypeEnum.String,
                        sortable: false,
                        label: 'Nume produs'
                    },
                    {
                        id: 'stock',
                        alignment: 'center',
                        headerType: HeaderTypeEnum.String,
                        sortable: false,
                        label: 'Stoc valabil/necesar'
                    },
                    {
                        id: 'deficit',
                        alignment: 'right',
                        headerType: HeaderTypeEnum.String,
                        sortable: false,
                        label: 'Deficit'
                    }
                ]
            }
        },
        [parsedData]
    )

    const customRenderer: CustomRendererPropsType = {
        name: (row: InssuficientStockLogItemType) => {
            return (
                <>
                    <div className="product-sku">{row.sku}</div>
                    <div className="product-name">{shortenString(row.name, 25)}</div>
                </>
            )
        },
        stock: (row: InssuficientStockLogItemType) => (
            <>
                <span className="log-stock available">{row.availableQuantity}</span>
                <span className="log-stock separator">/</span>
                <span className="log-stock needed">{row.neededQuantity}</span>
            </>
        ),
        deficit: (row: InssuficientStockLogItemType) => <span className="log-deficit">-{row.deficit}</span> 
    };


    /** define the return statement bellow */
    return (
        <InssuficientStockErrorComponentStyled>
            <Container maxWidth='xl' style={{marginTop: '1.5rem'}}>
                <TableComponent
                    tableKey={TableTypeEnum.InsufficientStock}
                    tableData={tableData}
                    viewType="read-only"
                    withoutSearchBar
                    withoutSelect
                    withoutDenseSwitch
                    denseByDefault
                    customRenderer={customRenderer}
                />    
            </Container>
        </InssuficientStockErrorComponentStyled>
    )

}

export default InssuficientStockErrorComponent;