import styled from "styled-components";
import { themeVariables } from "../../theme/variables";

const PanelItemComponentStyled = styled.div`

    display: flex;
    align-items: flex;

    .icon-section {
        margin-right: 1rem;
        /* border: 1px solid rgba(0, 0, 0, 0.25); */
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        width: 3.1rem;
        height: 3.1rem;
    }

    .content-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
            font-size: ${themeVariables.fontSizes.normal};
            font-weight: ${themeVariables.fontWeights.semibold};
        }

        .description {
            margin-top: -0.2rem;
            color: gray;
            font-size: ${themeVariables.fontSizes.subtitle};
        }
    }

    .action-section {
        margin-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

`;

export default PanelItemComponentStyled;