import React from "react";
import { RootStore } from './root.store';

const rootStore = new RootStore();

export const stores = Object.freeze({
    rootStore: rootStore,
    stores: {
        uiStore: rootStore.uiStore,
        userStore: rootStore.userStore,
        tableStore: rootStore.tableStore,
        websocketStore: rootStore.websocketStore
    }
});

export const StoresContext = React.createContext(stores);