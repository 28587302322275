import * as React from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextFieldComponent from '../text-field/text-field.component';

export type DatePickerComponentPropsType = {
    value: Date,
    setValue: (e: any) => void;
    label: string;
}

const DatePickerComponent = ({
    value,
    setValue,
    label
}: DatePickerComponentPropsType) => {
    
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => 
                  <TextFieldComponent 
                    {...params} 
                    fullWidth={true}
                    variant="outlined" 
                    label={label} 
                  />}
                inputFormat="dd.MM.yyyy"
                mask="__.__.____"
            />
        </LocalizationProvider>
    )
}

export default DatePickerComponent;