import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import TableComponent, { ActionItemPropsType } from "../../../../components/table/table.component";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import CustomersPageStyled from "./customers.page.styled";
import { PanelType } from "../../../../enums/panel-type.enum";
import { useStore } from "../../../../hooks/store.hook";
import CustomerPanelComponent from "../../../../components/customer-panel/customer-panel.component";
import { DeleteOutlined, EditOutlined, History } from "@mui/icons-material";
import { deleteCustomer } from "../../../../utils/requests";
import toast from "react-hot-toast";
import { DialogResponseTypeEnum } from "../../../../enums/dialog-response-type.enum";
import { DataSourceEnum } from "../../../../enums/data-source.enum";
import CustomerImportPanelComponent from "../../../../components/customer-import-panel/customer-import-panel.component";
import CustomerDetailsComponent, { CustomerDetailsTab } from "../../../../components/customer-details/customer-details.component";

const CustomersPage = () => {
    const uiStore = useStore('uiStore');
    const tableStore = useStore('tableStore')

    const buttons = [
        {
            text: "Import",
            fOnClick: () => {
                uiStore.openPanel({
                    key: PanelType.ImportCustomer,
                    component: <CustomerImportPanelComponent />,
                    title: 'Import clienti'
                })
            }
        },
        {
            text: "Creeaza client",
            fOnClick: () => {
                uiStore.openPanel({
                    key: PanelType.Customer,
                    component: <CustomerPanelComponent title='Adaugare Client' />,
                });
            }
        },
    ]

    const actionItems: ActionItemPropsType[] = [
        {
            text: "Editeaza",
            icon: <EditOutlined />,
            color: "black",
            fOnClick: (row: any) => {
                uiStore.openPanel({
                    key: PanelType.Customer,
                    component: <CustomerDetailsComponent 
                                    customerId={row.id} 
                                    title={row.fullName && row.fullName !== '-'? row.fullName : row.email} 
                                />,
                });
            }
        },
        {
            text: "Vezi Istoric",
            icon: <History />,
            color: "black",
            fOnClick: (row: any) => {
                uiStore.openPanel({
                    key: PanelType.ViewClientHistory,
                    component: <CustomerDetailsComponent 
                                    customerId={row.id} 
                                    tabFocused={CustomerDetailsTab.ActionLog} 
                                    title={row.fullName && row.fullName !== '-' ? row.fullName : row.email} 
                                />,
                });
            }
        },
        {
            text: "Sterge",
            icon: <DeleteOutlined/>,
            color: "red",
            async fOnClick(row: any){
                if(row) {   
                    const response = await uiStore.openDialog({
                        title: "Stergi clientul...",
                        message: "Esti sigur ca vrei sa continui?",
                    })    
                    if(response.value === DialogResponseTypeEnum.Confirm) {
                        try {
                            await deleteCustomer(row.id)
                            toast.success('Clientul a fost sters cu succes');
                            tableStore.updateTable(TableTypeEnum.Customers)
                        }
                        catch (e: any) {
                            toast.error(e.message)
                        }
                    }
                        
                } 
            }
        }
    ]
   

    /** define the return statement bellow */
    return (
        <CustomersPageStyled>

            <PageTemplateComponent title="Clienti" buttons={buttons}>
                <TableComponent 
                    tableKey={TableTypeEnum.Customers} 
                    url="/customer" 
                    searchBarPlaceholder="Cauta dupa nume, email sau numar de telefon" 
                    actionItems={actionItems}
                    withoutSelect
                    dropdownOptions={Object.values(DataSourceEnum).map(source => ({ label: source, value: source }))}   
                />
            </PageTemplateComponent>

        </CustomersPageStyled>
    )

}

export default CustomersPage;