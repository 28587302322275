import React from "react";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import UsersPageStyled from "./users.page.styled";
import TableComponent, { ActionItemPropsType } from "../../../../components/table/table.component";
import { RoleEnum } from "../../../../enums/role.enum";
import { EditOutlined, ToggleOff } from '@mui/icons-material';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import { useStore } from "../../../../hooks/store.hook";
import UserPanelComponent from "../../../../components/user-panel/user-panel.component";
import { PanelType } from "../../../../enums/panel-type.enum";
import { toggleUserActivation } from "../../../../utils/requests";
import toast from "react-hot-toast";

const UsersPage = () => {
    const uiStore = useStore('uiStore');
    const tableStore = useStore('tableStore');

    var tabsOptions = [
        {
            label:"Activ",
            value: true,
        },
        {
            label:"Inactiv",
            value: false,
        },
    ]

    var actionItems: ActionItemPropsType[] = [
        {
            text: "Editeaza",
            icon: <EditOutlined/>,
            color: "black",
            fOnClick: (row: any) => {
                uiStore.openPanel({
                    key: PanelType.EditUser,
                    component: <UserPanelComponent userId={row.id} />,
                    title: `Editeaza utilizatorul: ${row.fullName}`
                });
            }
        },
        {
            text: "Dezactiveaza",
            icon: <ToggleOff/>,
            color: "red",
            canDisplay: (row: any) => row.status.value,
            fOnClick: (row: any) => {
                if(row)
                {  
                    toggleUserActivation(row.id)
                    .then( 
                        () => {
                            toast.success('Clientul a fost dezactivat cu succes');
                            tableStore.updateTable(TableTypeEnum.Users)
                        })
                    .catch((e) => toast.error(e.message))
                }
            }
        },
        {
            text: "Activeaza",
            icon: <ToggleOnIcon/>,
            color: "green",
            canDisplay: (row: any) => !row.status.value,
            fOnClick: (row: any) => {
                if(row)
                { 
                    toggleUserActivation(row.id)
                    .then( 
                        () => {
                            toast.success('Clientul a fost activat cu succes');
                            tableStore.updateTable(TableTypeEnum.Users)
                        })
                    .catch((e) => toast.error(e.message))
                }
            }
        },
    ]

    const buttons = [
        {
            text: "Creeaza utilizator",
            fOnClick: () => {
                uiStore.openPanel({
                    key: PanelType.AddUser,
                    component: <UserPanelComponent />,
                    title: 'Creeaza un utilizator'
                });
            }
        },
    ]


    /** define the return statement bellow */
    return (
        <UsersPageStyled>
            
            <PageTemplateComponent title="Utilizatori" buttons={buttons}>
                <TableComponent 
                    tableKey={TableTypeEnum.Users} 
                    url="/user" 
                    searchBarPlaceholder="Cauta dupa nume sau email" 
                    dropdownOptions={Object.values(RoleEnum).map(role => ({ label: role, value: role }))} 
                    tabs={tabsOptions} 
                    actionItems={actionItems} 
                    withoutSelect
                />
            </PageTemplateComponent>

        </UsersPageStyled>
    )

}

export default UsersPage;
