import styled from "styled-components";

const CustomerPanelComponentStyled = styled.div`

    .button-container {
        margin-top: 0.5rem;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1.5rem;
    }


`;

export default CustomerPanelComponentStyled;