import { DownloadForOffline } from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { TableTypeEnum } from "../../enums/table-type.enum";
import { useStore } from "../../hooks/store.hook";
import { downloadCustomersImportTemplate, uploadCustomerImport } from "../../utils/requests";
import ButtonComponent from "../button/button.component";
import DropzoneUploadComponent from "../dropzone-upload/dropzone-upload.component";
import CustomerImportPanelComponentStyled from "./customer-import-panel.component.styled";

const CustomerImportPanelComponent = () => {

    const uiStore = useStore('uiStore');
    const tableStore = useStore('tableStore');

    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const onDownloadTemplate = useCallback(
        () => {
            setIsDownloading(() => true);
            downloadCustomersImportTemplate()
                .catch((e: any) => {
                    toast.error(e.message);
                })
                .finally(() => {
                    setIsDownloading(() => false);
                })
        },
        []
    )

    useEffect(
        () => {
            if(selectedFile)
                uiStore.setOrderData(true)
            else
                uiStore.setOrderData(false)
        },
        [
            uiStore,
            selectedFile
        ]
    )

    useEffect(
        () => {
            uiStore.updatePanel({
                subtitleComponent: () => (
                    <ButtonComponent 
                        color="secondary"
                        variant="outlined"
                        isLoading={isDownloading}
                        startIcon={<DownloadForOffline />}
                        onClick={onDownloadTemplate}
                    >
                        Descarca raport clienti
                    </ButtonComponent>
                )
            })
        },
        [uiStore, onDownloadTemplate, isDownloading]
    )

    const onFileChangeHandler = useCallback(
        (file: File | null) => {
            setSelectedFile(() => file);
        },
        []
    )

    const onUpload = useCallback(
        () => {
            if (!selectedFile) return;

            setIsUploading(() => true);
            uploadCustomerImport(selectedFile)
                .then(() => {
                    toast.success('Import-ul s-a realizat cu succes');
                    tableStore.updateTable(TableTypeEnum.Customers);
                })
                .catch((e: any) => {
                    toast.error(e.message);
                })
                .finally(() => {
                    setIsUploading(() => false);
                });
        },
        [selectedFile, tableStore]
    )


    /** define the return statement bellow */
    return (
        <CustomerImportPanelComponentStyled>

            <DropzoneUploadComponent
                onFileChange={onFileChangeHandler}
            />

            <div className="button-container">
                <ButtonComponent onClick={onUpload} isLoading={isUploading} disabled={!selectedFile}>
                    Salveaza
                </ButtonComponent>
            </div>

        </CustomerImportPanelComponentStyled>
    )

}

export default CustomerImportPanelComponent;