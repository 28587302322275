import { Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import TextFieldComponent from "../text-field/text-field.component";
import OrderHistoryHeaderComponentStyled from "./order-history-header.component.styled";

export type OrderHistoryHeaderDatesType = {
    startDate?: Date,
    endDate?: Date,
}

export type OrderHistoryHeaderComponentPropsType = {
    minDate: Date,
    maxDate: Date,
    onDatesChangeCB?: (newDates: OrderHistoryHeaderDatesType) => void
}

const OrderHistoryHeaderComponent = ({
    minDate,
    maxDate,
    onDatesChangeCB
}: OrderHistoryHeaderComponentPropsType) => {

    const [dates, setDates] = useState<OrderHistoryHeaderDatesType>({
        startDate: undefined,
        endDate: undefined
    });

    useEffect(
        () => {
            setDates(() => ({
                startDate: minDate,
                endDate: maxDate
            }))
        },
        [minDate, maxDate]
    )

    const onDatesChange = useCallback(
        (key: 'startDate' | 'endDate', newValue: moment.Moment | null) => {
            const newDates = {
                ...dates,
                [key]: (newValue as moment.Moment).toDate()
            };

            setDates(() => newDates);
            if(onDatesChangeCB)
                onDatesChangeCB(newDates);
        },
        [onDatesChangeCB, dates]
    )

    /** define the return statement bellow */
    return (
        <OrderHistoryHeaderComponentStyled>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <DatePicker
                        minDate={moment(minDate)}
                        maxDate={moment(dates.endDate ?? new Date())}
                        value={dates.startDate}
                        renderInput={(params) => 
                            <TextFieldComponent 
                                {...params} 
                                fullWidth={true} 
                                size="small" 
                                variant="outlined" 
                                label="Data inceput" 
                            />}
                        onChange={newValue => onDatesChange('startDate', newValue)}
                        mask="__.__.____"
                    />
                </Grid>

                <Grid item xs={6}>
                    <DatePicker
                        minDate={moment(dates.startDate)}
                        maxDate={moment(maxDate)}
                        value={dates.endDate}
                        renderInput={(params) => <TextFieldComponent {...params} fullWidth={true} size="small" variant="outlined" label="Data final" />}
                        onChange={newValue => onDatesChange('endDate', newValue)}
                        mask="__.__.____"
                    />
                </Grid>
            </Grid>
            


        </OrderHistoryHeaderComponentStyled>
    )


}

export default OrderHistoryHeaderComponent;