import styled from "styled-components";
import { themeVariables } from "../../theme/variables";

export const TextOneStyled = styled.div`
    text-align: center;
    font-size: ${themeVariables.fontSizes.smaller}; 
`

export const TextTwoStyled = styled.div`
    text-align: center;
    font-size: ${themeVariables.fontSizes.smaller};
    font-weight: ${themeVariables.fontWeights.bold}; 
`

export const TitleStyled = styled.div`
    display: flex;
    justify-content: center;
    font-size: ${themeVariables.fontSizes.normal};
    font-weight: ${themeVariables.fontWeights.bold}; 
`