import styled from "styled-components";


export const DonutPatternChartStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center; 
    height: 100%;
    padding-bottom: 3rem
`

export const TitleDonutPatternChartStyled = styled.div`
    font-size: 1.125rem;
    font-weight: bold;
    padding: 1rem
`