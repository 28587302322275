import moment from 'moment';
import { RoleEnum } from '../enums/role.enum';
import { ExtendedAddressType } from '../types/address.type';
import { OrderProductType } from '../types/product.type';

export const shortenString = (text: string, limit: number): string => {
    if (!text) return '';
    return text.length < limit ? text : text.substring(0, limit) + '...'; 
}

export const formatDate = (date: Date): string => {
    return moment(date).format('d MMM, YYYY');
}

export const getBillingOtherDetailsFromShipping = (address?: ExtendedAddressType): string => {
    if (!address?.street) return '';
    let addressTokens = [];
    
    if (address.street)
        addressTokens.push(`str ${address.street}`);
    if (address.streetNumber)
        addressTokens.push(`nr. ${address.streetNumber}`);
    if (address.flat)
        addressTokens.push(`bloc ${address.flat}`);
    if (address.building)
        addressTokens.push(`scara ${address.building}`);
    if (address.apartmentNumber)
        addressTokens.push(`ap. ${address.apartmentNumber}`);
    
    var streetConcatenated = addressTokens.join(', ');
    return streetConcatenated[0].toUpperCase() + streetConcatenated.substring(1);
}

export const getKitPriceToDistribute = (product: OrderProductType, kitQuantity: number): number => {
    const kitPrice = +product.price * +kitQuantity;
    const kitProductsPrice = (product.childProducts ?? []).reduce((prev, curr) => {
        return prev + (+curr.price * +kitQuantity * +curr.stockTotal);
    }, 0)
    const kitProductsQuantity = (product.childProducts ?? []).reduce((prev, curr) => {
        return prev + (+kitQuantity * +curr.stockTotal);
    }, 0)
    
    return Math.max(kitProductsPrice-kitPrice, 0)/kitProductsQuantity;
}

export const formatNumber = (value: number) => {
    if(!value) return 0

    var valueString = value.toString()
    const indexDot = valueString.indexOf('.')
    var valueNoDot
    if(indexDot > 0){
        valueString = Number(valueString).toFixed(2)
        valueNoDot = valueString.slice(0, indexDot)
    }
    else 
        valueNoDot = valueString

    const valueLen  = valueNoDot.toString().length
    var formattedNumber = valueNoDot
    var c = 1

    if(valueLen > 3){
        for(let i = valueLen - 1; i >= 0; i = i - 1){
            if( c % 3 === 0)
                formattedNumber = `${valueNoDot.slice(0,i)}.${formattedNumber.slice(i, formattedNumber.length)}`
            c = c + 1
        }
    }

    if( indexDot > 0 )
        formattedNumber = `${formattedNumber},${valueString.slice(indexDot + 1, valueString.length)} `

    if(formattedNumber[0] === '.')
        formattedNumber = formattedNumber.slice(1, formattedNumber.length)

    return `${formattedNumber}`
}

export const isAdmin = (roles: RoleEnum[] | undefined, f?: any) => {

    if(f)
        if(roles && roles.indexOf(RoleEnum.Admin) >= 0)
            return f
        else 
            return undefined
    else 
        if(roles && roles.indexOf(RoleEnum.Admin) >= 0)
            return true
        else 
            return false
}