import styled from "styled-components";
import { themeVariables } from "../../theme/variables";

const OrderDetailsSummaryTabComponentStyled = styled.div`

    .price-without-discount {
        position: relative;

        .line-throught {
            position: absolute;
            background: gray;
            height: 1px;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        color: gray;
        font-size: ${themeVariables.fontSizes.smaller};
        text-decoration: line-through;
    }

    .total-price-wrapper {
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        &.child-wrapper {
            margin-right: 4rem;
        }
    }

`;

export default OrderDetailsSummaryTabComponentStyled;