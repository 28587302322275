import { toJS } from "mobx";
import React, { useCallback, useMemo, useState } from "react";
import { TableTypeEnum } from "../../enums/table-type.enum";
import { useStore } from "../../hooks/store.hook";
import ButtonComponent from "../button/button.component";
import TableComponent, { CustomRendererPropsType, SelectedStatePropsType } from "../table/table.component";
import SelectOrderProductsStyledComponent from "./select-order-products.component.styled";
import { getSelectedProducts } from "../../utils/requests";
import { SelectedProductsType } from "../../types/order.type";
import toast from "react-hot-toast";
import { CurrencyEnum } from "../../enums/currency.enum";

export type SelectOrderProductsComponentPropsType = {
    preselectedItems: any[];
}



const SelectOrderProductsComponent = ({
    preselectedItems
}: SelectOrderProductsComponentPropsType) => {

    /** inject mobx store inside  */
    const uiStore = useStore('uiStore');

    const [selectedProductsState, setSelectedProductsState] = useState<SelectedStatePropsType>({
        isAllSelected: false,
        selectedItems: []
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const parsedPreselectedItems = useMemo(
        () => {
            return toJS(preselectedItems);
        },
        [preselectedItems]
    )

    const customRenderer: CustomRendererPropsType = {
        name: (row) => {
            return (
                <>
                    <div className="product-sku">{row.sku}</div>
                    <div className="product-name">{row.name}</div>
                </>
            )
        },
        price: (row) => `${row.price} ${CurrencyEnum.RON}`
    };
   
    const onSelectProducts = useCallback(
        async() => {
            
            if (selectedProductsState.isAllSelected) {
                /** get the rest of the products from the api */
                var selectedProducts: SelectedProductsType = {
                    isAllSelected: selectedProductsState.isAllSelected,
                    selectedItems: selectedProductsState.selectedItems
                }
                
                setIsLoading( () => true)

                try {
                    const products = await getSelectedProducts(selectedProducts)
                    uiStore.dismissPanel({data: products});
                }
                catch(e: any){
                    toast.error(e.message);
                }

                setIsLoading( () => false)

            } else {
                /** simply send the selected products from the table back to the parent */
                uiStore.dismissPanel({data: selectedProductsState.selectedItems});
            }
        },
        [selectedProductsState.isAllSelected, selectedProductsState.selectedItems, uiStore]
    )

    const onSelectRows = useCallback(
        (selectedState: SelectedStatePropsType) => {
            setSelectedProductsState(() => selectedState);
        },
        []
    )

    /** define the return statement bellow */
    return (
        <SelectOrderProductsStyledComponent>
            <TableComponent
                withoutPersist
                tableKey={TableTypeEnum.SelectOrderProducts}
                searchBarPlaceholder="Cauta dupa nume sau sku"  
                url="/product/get-select-order-products"
                customRenderer={customRenderer}
                withoutDenseSwitch
                denseByDefault
                preselectedItems={parsedPreselectedItems}
                viewType="panel"
                onSelectRows={onSelectRows}
            />

            <div className="button-container">
                <ButtonComponent onClick={onSelectProducts} isLoading={isLoading}>
                    Selecteaza
                </ButtonComponent>
            </div>
        </SelectOrderProductsStyledComponent>
    )

}

export default SelectOrderProductsComponent;