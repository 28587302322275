import { Add } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useStore } from "../../hooks/store.hook";
import { OrderType } from "../../types/order.type";
import { UserListType, UserType } from "../../types/user.type";
import { assignOrderUser } from "../../utils/requests";
import AsyncSelectComponent, { AsyncSelectOptionType } from "../async-select/async-select.component";
import ButtonComponent from "../button/button.component";
import UserListItemComponent from "../user-list-item/user-list-item.component";
import OrderAssignUserComponentStyled from "./order-assign-user.component.styled";

export type OrderAssignUserComponentPropsType = {
    orderDetails: OrderType
}

const OrderAssignUserComponent = ({
    orderDetails
}: OrderAssignUserComponentPropsType) => {

    const userStore = useStore('userStore');
    const uiStore = useStore('uiStore');

    const [selectedUser, setSelectedUser] = useState<UserListType | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(
        () => {
            if (orderDetails.assignedUser) {
                const user = orderDetails.assignedUser as UserType;
                setSelectedUser(() => ({
                    email: user.email,
                    fullName: user.fullName,
                    id: user.id,
                }));
            }
        },
        [orderDetails, userStore.user]
    )

    const onAssignMe = useCallback(
        () => {
            setSelectedUser(() => ({
                email: userStore.user?.email ?? '',
                fullName: userStore.user?.fullName ?? '',
                id: userStore.user?.id ?? ''
            }))
        },
        [userStore.user]
    )

    useEffect(
        () => {
            uiStore.updatePanel({
                subtitleComponent: () => (
                    <ButtonComponent 
                        color="secondary"
                        variant="outlined"
                        startIcon={<Add />}
                        onClick={onAssignMe}
                    >
                        Asigneaza-ma
                    </ButtonComponent>
                )
            })   
        },
        [orderDetails, uiStore, onAssignMe]
    )

    const onAssignUser = useCallback(
        async () => {

            setIsLoading(() => true);
            try {
                const updatedOrder = await assignOrderUser(orderDetails.id, selectedUser?.id);
                toast.success('Modificarea s-a realizat cu succes');
                uiStore.dismissPanel({
                    data: updatedOrder
                })
            } catch(e: any) {
                toast.error(e.message);
            }
            setIsLoading(() => false);
           
        },
        [selectedUser, uiStore, orderDetails.id]
    )

    const onUserOptionChange = useCallback(
        async (user: AsyncSelectOptionType | null) => {
            setSelectedUser(() => user as UserListType);
        },
        []
    )

    /** define the return statement bellow */
    return (
        <OrderAssignUserComponentStyled>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AsyncSelectComponent
                        placeholder="Asigneaza un utilizator"
                        label="Utilizator"
                        url="/user/get-list"
                        value={selectedUser}
                        renderOption={(props, option) => (
                            <Box style={{display: 'flex', alignItems: 'center'}} component="li" {...props}>
                                <UserListItemComponent
                                    fullName={option.fullName}
                                    email={option.email}
                                    id={option.id as string}
                                    withSelfChip
                                />
                            </Box>
                        )}
                        onOptionChange={onUserOptionChange}
                        getOptionLabel={ option => [option.fullName, option.email].filter(f => f).join(' - ') }
                        isOptionEqualToValue={(option, selectedUser) => ( option.id === selectedUser.id ? true : false ) }
                    />
                </Grid>
            </Grid> 

            <div className="button-container">
                <ButtonComponent onClick={onAssignUser} isLoading={isLoading}>
                    Salveaza
                </ButtonComponent>
            </div>

        </OrderAssignUserComponentStyled>
    )

}

export default OrderAssignUserComponent;