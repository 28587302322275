import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/Logout';
import AvatarComponent from '../avatar/avatar.component';
import { Person } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/store.hook';
import { Chip, Divider, ListItem } from '@mui/material';
import { themeVariables } from '../../theme/variables';
import AvatarMenuComponentStyled from './avatar-menu.component.styled';
import { DialogResponseTypeEnum } from '../../enums/dialog-response-type.enum';

const AvatarMenuComponent = observer(() => {

  /** inject the user store into the component */
  const userStore = useStore('userStore');
  const tableStore = useStore('tableStore');
  const uiStore = useStore('uiStore');
  
  
  /** inject navigator to navigate to other routes */
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(() => event.currentTarget);
    },
    []
  );

  const handleClose = React.useCallback(
    () => {
      setAnchorEl(() =>null);
    },
    []
  );

  const onProfileMenuItemClick = React.useCallback(
    async() => {
      if(tableStore.updatedData?.length){
        const response = await uiStore.openDialog({
          title: "Ai date nesalvate!",
          message: "Vrei sa parasesti pagina?",
        })    
        if(response.value === DialogResponseTypeEnum.Confirm){
          uiStore.dismissPersistentToast()
          tableStore.resetOldUpdatedData()
          navigate('/app/profile');
          tableStore.setUpdatedData([])
        }
      }
      else 
        navigate('/app/profile');
    },
    [navigate, tableStore, uiStore]
  )

  const LogoutClick = React.useCallback(
    async() => {
      if(tableStore.updatedData?.length){
        const response = await uiStore.openDialog({
            title: "Ai date nesalvate!",
            message: "Vrei sa parasesti pagina?",
        })    
        if(response.value === DialogResponseTypeEnum.Confirm){
          uiStore.dismissPersistentToast()
          tableStore.resetOldUpdatedData()
          navigate('/auth/logout');
          tableStore.setUpdatedData([])
        }
      }
      else 
        navigate('/auth/logout');
    },
    [navigate, tableStore, uiStore]
  )

  /** define the return statement bellow */
  return (
    <AvatarMenuComponentStyled>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <div className="account-info">
          <div className='user-name'>{userStore.user?.fullName ?? ''}</div>
          <div className='user-roles'>
            {
              (userStore.user?.roles ?? []).map(role => (
                <Chip
                  style={{marginLeft: '0.3rem'}}
                  key={role}
                  size='small'
                  label={role}
                />
              ))
            }
          </div>
        </div>
        <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={!!anchorEl ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={!!anchorEl ? 'true' : undefined}
          >
            <AvatarComponent name={userStore.user?.fullName ?? ''} />
        </IconButton>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={!!anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 22,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        <ListItem style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            fontSize: themeVariables.fontSizes.subtitle
        }}>
          <div style={{
            fontWeight: themeVariables.fontWeights.medium
          }}>
            {userStore.user?.fullName ?? ''}
          </div>
          <div style={{
            color: 'gray'
          }}>
            {userStore.user?.email ?? ''}
          </div>
        </ListItem>

        <Divider />

        <MenuItem onClick={onProfileMenuItemClick}>
          <ListItemIcon>
            <Person fontSize="small" />
          </ListItemIcon>
          Vezi profilul
        </MenuItem>

        <MenuItem  onClick={LogoutClick}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Iesi din cont
        </MenuItem>
      </Menu>
    </AvatarMenuComponentStyled>
  );
})

export default AvatarMenuComponent;