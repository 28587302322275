import styled from "styled-components";
import { themeVariables } from "../../../../theme/variables";

const OrdersPageStyled = styled.div`

    .order-customer {
        font-weight: ${themeVariables.fontWeights.medium};
    }

    .order-id {
        color: gray;
        font-size: ${themeVariables.fontSizes.smaller};
    }

    .assigned-user-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

`;

export default OrdersPageStyled;