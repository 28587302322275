import React  from "react";
import ButtonComponent from "../button/button.component";
import PanelItemComponentStyled from "./panel-item.component.styled";

export type PanelItemComponentPropsType = {
    title: string;
    description?: string;
    panelIcon: React.ReactNode;
    action?: {
        title: string;
        onClick: () => void
    }
}

export type PanelItemIconComponentPropsType = {
    color: string;
    backgroundColor: string;
    icon: React.ReactNode;
}

export const PanelItemIconComponent = ({
    color,
    backgroundColor,
    icon
}: PanelItemIconComponentPropsType) => {

    return (
        <div className="icon-section" style={{background: backgroundColor, color: color}}>
            {icon}
        </div>
    )

}

const PanelItemComponent = ({
    title,
    description,
    panelIcon,
    action 
}: PanelItemComponentPropsType) => {

    /** define the return statement bellow */
    return (
        <PanelItemComponentStyled>

            {panelIcon}

            <div className="content-section">

                <div className="title">
                    {title}
                </div>

                {
                    description ?
                    <div className="description">
                        {description}
                    </div> :
                    ''
                } 

            </div>

            {
                action ?
                    <div className="action-section">
                        <ButtonComponent 
                            color="success"
                            variant="outlined"
                            onClick={action.onClick}
                        >
                            {action.title}
                        </ButtonComponent>
                    </div> :
                    ''
            }
            

        </PanelItemComponentStyled>
    )

}

export default PanelItemComponent;