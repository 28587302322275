import styled from "styled-components";
import { themeVariables } from "../../theme/variables";

const UserListItemComponentStyled = styled.div`

    display: flex;
    align-items: center;
    width: 100%;

    .avatar-icon {
        margin-right: 1rem;
    }

    .content {
        flex: 1;

        .content-subtitle {
            color: gray; 
            font-size: ${themeVariables.fontSizes.smaller};
        }
    }

`;

export default UserListItemComponentStyled;