import { Add, Delete } from "@mui/icons-material";
import { Grid, InputAdornment, Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { TableTypeEnum } from "../../enums/table-type.enum";
import { useStore } from "../../hooks/store.hook";
import { StateFieldType } from "../../types/form.types";
import { ChildProductType, CreateProductType, ProductType, UpdateProductType } from "../../types/product.type";
import { createProduct, getProduct, updateProduct } from "../../utils/requests";
import ButtonComponent from "../button/button.component";
import FormSectionComponent from "../form-section/form-section.component";
import TextFieldComponent from "../text-field/text-field.component";
import ProductPanelComponentStyled from "./product-panel.component.styled"
import AsyncSelectComponent from "../async-select/async-select.component";
import { DictionaryType } from "../../types/dictionary.type";
import { ProductCategoryEnum } from "../../enums/product-category.enum";
import SelectOrderProductsComponent from "../select-order-products/select-order-products.component";
import { PanelType } from "../../enums/panel-type.enum";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableDataPropsType } from "../table/table.component";
import { HeaderTypeEnum } from "../../enums/header-type.enum";
import { shortenString } from "../../utils/utils";
import StockSelectComponent from "../stock-select/stock-select.component";
import { CurrencyEnum } from "../../enums/currency.enum";

type StateType = {
    fields: {
        sku: StateFieldType<string>;
        name: StateFieldType<string>;
        vendor: StateFieldType<string>;
        price: StateFieldType<string>;
        category: StateFieldType<DictionaryType>;
        childProducts: StateFieldType<ChildProductType[]>;
    };
    shouldDisplayError: boolean;
}

export type ProductPanelComponentPropsType = {
    productId?: string;
}

const ProductPanelComponent = ({
    productId
}: ProductPanelComponentPropsType) => {

    /** inject mobx store inside  */
    const uiStore = useStore('uiStore');
    const tableStore = useStore('tableStore');

    const [state, setState] = useState<StateType>({
        fields: {
            sku: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati un id pentru produs'
            },
            name: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati un nume pentru produs'
            },
            vendor: {
                value: '',
                isValid: true,
                noValidation: true
            },
            price: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati un pret valid pentru produs',
                validator: (newValue: string) => Number(newValue) > 0
            },
            category: {
                value: { label: '' },
                isValid: true,
                noValidation: true
            },
            childProducts: {
                value: [],
                isValid: true,
                noValidation: true
            }
        },
        shouldDisplayError: false
    });
    const [isLoading, setIsLoading] = useState(false);

    const updateStateField = useCallback(
        (currentField: StateFieldType<any>, newValue: any): StateFieldType<any> => {
            return {
                ...currentField,
                isValid: (currentField.validator ? (currentField.validator as any)(newValue) : !!newValue) ||
                    currentField.noValidation,
                value: newValue
            }
        },
        []
    )

    const updateState = useCallback(
        <T extends keyof typeof state.fields>(field: T, newValue: any) => {
            setState(state => ({
                ...state,
                fields: {
                    ...state.fields,
                    [field]: updateStateField(state.fields[field], newValue)
                }
            }))
        },
        [state, updateStateField]
    )

    useEffect(
        () => {
                if(state.fields.category.value.label
                    || state.fields.childProducts.value.length
                    || state.fields.price.value 
                    || state.fields.sku.value
                    || state.fields.vendor.value)
                        uiStore.setOrderData(true)
                else
                    uiStore.setOrderData(false)
            
        },
        [
            state.fields.category.value.label, 
            state.fields.childProducts.value.length, 
            state.fields.price.value, 
            state.fields.sku.value, 
            state.fields.vendor.value, 
            uiStore
        ]
    )

    useEffect(
        () => {
            if (productId) {
                uiStore.updatePanel({ title: 'Editare produs' });

                getProduct(productId)
                    .then(product => {
                        setState(state => ({
                            ...state,
                            fields: {
                                ...state.fields,
                                sku: updateStateField(state.fields.sku, product.sku ?? ''),
                                name: updateStateField(state.fields.name, product.name ?? ''),
                                price: updateStateField(state.fields.price, product.price ?? 0),
                                vendor: updateStateField(state.fields.vendor, product.vendor ?? ''),
                                category: updateStateField(state.fields.category, { label: product.category ?? '' }),
                                childProducts: updateStateField(state.fields.childProducts, (product.childProducts ?? []).map(prod => ({
                                    ...(prod.product as ProductType),
                                    selectedStock: prod.quantity                 
                                })))
                            }
                        }));
                    })
                    .catch(e => toast.error(e.message));
            } else {
                uiStore.updatePanel({ title: 'Adaugare produs' });
            }
        },
        [uiStore, productId, updateStateField]
    )

    const isEdit = useMemo(
        (): boolean => {
            return !!productId;
        },
        [productId]
    )

    const userAction = useCallback(
        async () => {
            setState(state => ({
                ...state,
                shouldDisplayError: true
            }));
           
            const isNotValid = Object.values(state.fields).some(field => !field.isValid);
            if (isNotValid) return;

            var data: CreateProductType | UpdateProductType = {
                name: state.fields.name.value,
                sku: state.fields.sku.value,
                price: state.fields.price.value,
                vendor: state.fields.vendor.value,
                category: !state.fields.category.value.label ? undefined : state.fields.category.value.label,
                childProducts: state.fields.category.value.label === ProductCategoryEnum.Kit ? state.fields.childProducts.value.map(prod => ({
                    product: prod.id,
                    quantity: prod.selectedStock
                })) : undefined
            };

            setIsLoading(() => true);
            try {
                if (productId) {
                    /** edit the product */
                    await updateProduct(productId, data);
                } else {
                    /** create the new product */
                    await createProduct(data);
                }
                toast.success('Produsul a fost salvat cu succes');
                uiStore.dismissPanel();
                tableStore.updateTable(TableTypeEnum.Products)
            } catch (e: any) {
                toast.error(e.message);
            }
            setIsLoading(() => false);

        },
        [state, productId, uiStore, tableStore]
    )

    useEffect(
        () => {
            if (state.fields.category.value.label !== ProductCategoryEnum.Kit) {
                updateState('childProducts', []);
            }
        },
        [state.fields.category.value, updateState]
    )

    const onChooseChildProducts = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.AddProduct,
                title: "Selecteaza produsele",
                panelWidth: '650px',
                component: <SelectOrderProductsComponent preselectedItems={state.fields.childProducts.value} />,
                onDismiss: (data) => {
                    if (!data) return;
                    updateState('childProducts', (data as ChildProductType[])
                        .map(prod => {
                            const selectedProd = state.fields.childProducts.value.find(f => f.id === prod.id);
                            if (selectedProd) return selectedProd;

                            return {
                                ...prod,
                                selectedStock: 1
                            }
                        })
                    );
                }
            })
        },
        [uiStore, state.fields.childProducts.value, updateState]
    )

    var actionItems = useMemo(
        (): ActionItemPropsType[] => {
            return [
                {
                    text: "Sterge",
                    icon: <Delete />,
                    color: "black",
                    fOnClick: (row: any) => {
                        updateState('childProducts', state.fields.childProducts.value.filter(f => f.id !== row.id));
                    }
                },
            ]        
        },
        [state.fields.childProducts.value, updateState]
    )

    const customRenderer = useMemo(
        (): CustomRendererPropsType => {
            return {
                name: (row) => {
                    return (
                        <>
                            <div className="product-sku">{row.sku}</div>
                            <div className="product-name">{shortenString(row.name, 18)}</div>
                        </>
                    )
                },
                quantity: (row) =>{
                        return (
                            <StockSelectComponent
                                size="small"
                                variant="standard"
                                value={(row.selectedStock ?? '1').toString()}
                                maxWidth='100px'
                                onTextChange={newValue => updateState('childProducts', state.fields.childProducts.value.map(prod => {
                                    if (prod.id === row.id)
                                        return { ...prod, selectedStock: + newValue };
                                    return prod;
                                }))}
                            />
                        )
                    },
                totalPrice: (row) => {
                    return (
                        <div>
                            {(parseFloat(row.price) * row.selectedStock).toFixed(2)} {CurrencyEnum.RON}
                        </div>
                    )
                }
            }
        },
        [state.fields.childProducts.value, updateState]
    ) 

    const tableData = useMemo(
        (): TableDataPropsType => {
            return {
                data: state.fields.childProducts.value,
                headers: [
                    {
                        id: 'name',
                        label: 'Nume',
                        alignment: 'left',
                        sortable: false,
                        headerType: HeaderTypeEnum.String
                    },
                    {
                        id: 'quantity',
                        label: 'Cantitate',
                        alignment: 'center',
                        sortable: false,
                        headerType: HeaderTypeEnum.String
                    },
                    {
                        id: 'totalPrice',
                        label: 'Pret total',
                        alignment: 'right',
                        sortable: false,
                        headerType: HeaderTypeEnum.String
                    },
                ]
            }
    },
        [state.fields.childProducts.value]
    )

    const generateTableForSelectedProducts = useCallback(
        () => {
            if(state.fields.childProducts.value.length > 0)
                return (
                    <Grid item xs={12}>
                        <TableComponent
                            tableKey={TableTypeEnum.ProductChildProducts}
                            withoutSelect
                            viewType='panel'
                            withoutPagination
                            withoutSearchBar
                            withoutDenseSwitch
                            customRenderer={customRenderer}
                            tableData={tableData}
                            denseByDefault
                            actionItems={actionItems}
                        />
                    </Grid>
                )
            else 
                return ''
        },
        [actionItems, customRenderer, state.fields.childProducts.value.length, tableData]
    )


    /** define the return statement bellow */
    return (
        <ProductPanelComponentStyled>

            <FormSectionComponent variant="panel" title="Detalii generale">

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <TextFieldComponent
                            label="SKU"
                            variant="outlined"
                            fullWidth={true}
                            value={state.fields.sku.value}
                            error={state.shouldDisplayError && !state.fields.sku.isValid}
                            helperText={state.shouldDisplayError && !state.fields.sku.isValid && state.fields.sku.errorMessage}
                            onTextChange={e => updateState('sku', e)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextFieldComponent
                            label="Denumire"
                            variant="outlined"
                            fullWidth={true}
                            multiline
                            rows={3}
                            value={state.fields.name.value}
                            error={state.shouldDisplayError && !state.fields.name.isValid}
                            helperText={state.shouldDisplayError && !state.fields.name.isValid && state.fields.name.errorMessage}
                            onTextChange={e => updateState('name', e)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextFieldComponent
                            label="Vendor"
                            variant="outlined"
                            fullWidth={true}
                            value={state.fields.vendor.value}
                            error={state.shouldDisplayError && !state.fields.vendor.isValid}
                            helperText={state.shouldDisplayError && !state.fields.vendor.isValid && state.fields.vendor.errorMessage}
                            onTextChange={e => updateState('vendor', e)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <AsyncSelectComponent
                            label="Categorie"
                            url="/dictionary/product-categories"
                            value={!state.fields.category.value?.label ? null : state.fields.category.value}
                            onOptionChange={e => updateState('category', e)}
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {[option.label]}
                                </Box>
                            )}
                        />
                    </Grid>

                </Grid>

            </FormSectionComponent>

            <FormSectionComponent variant="panel" title="Pretul produsului">
                <Grid item xs={12}>
                    <TextFieldComponent
                        label="Pret"
                        type="number"
                        variant="outlined"
                        fullWidth={true}
                        value={state.fields.price.value}
                        error={state.shouldDisplayError && !state.fields.price.isValid}
                        helperText={state.shouldDisplayError && !state.fields.price.isValid && state.fields.price.errorMessage}
                        onTextChange={e => updateState('price', e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">RON</InputAdornment>,
                        }}
                    />
                </Grid>
            </FormSectionComponent>

            {
                state.fields.category.value.label === ProductCategoryEnum.Kit ?
                    <FormSectionComponent variant="panel" title="Produse kit">
                        <Grid container spacing={2}>
                            {
                                generateTableForSelectedProducts()     
                            }

                            <Grid item xs={12}>
                                <ButtonComponent
                                    variant="text"  
                                    onClick={onChooseChildProducts}
                                    size="medium"
                                    startIcon={<Add />}
                                    >
                                        Selecteaza produse
                                </ButtonComponent>
                            </Grid>
                        </Grid>
                    </FormSectionComponent> :
                    ''
            }
            

            <div className="button-container">
                <ButtonComponent onClick={userAction} isLoading={isLoading}>
                    {isEdit ? 'Salveaza' : 'Creeaza'}
                </ButtonComponent>
            </div>

        </ProductPanelComponentStyled>
    )

}

export default ProductPanelComponent;