import styled from "styled-components";
import { themeVariables } from "../../theme/variables";

const OrderDetailsHistoryTabComponentStyled = styled.div`

    margin-top: -1rem;

    .log-item-wrapper {

        display: flex;
        align-items: center;

        .date-section {
            width: 90px;
            color: gray;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            font-size: ${themeVariables.fontSizes.smaller}
        }

        .icon-section {
            margin-left: 1rem;
            margin-right: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;

            .line-section {
                background: lightgray;
                width: 1px;
                height: 1rem;
            }

            .avatar-section {
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
            }
        }

        .content-section {
            flex: 1;

            .content-user {
                font-size: ${themeVariables.fontSizes.smaller};
                color: ${themeVariables.colors.primary};
                margin-bottom: 0.1rem;
            }

            .content-message {
                font-size: ${themeVariables.fontSizes.subtitle};

                span.log-item-emphasis {
                    font-weight: ${themeVariables.fontWeights.semibold};
                }

                span.log-item-user {
                    color: ${themeVariables.colors.primary};
                }
            }
        }        

    }

`;

export default OrderDetailsHistoryTabComponentStyled;