import { LinearProgress } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { RoleEnum } from "../../enums/role.enum"
import { overallRangeDateReq } from "../../utils/requests"
import AuthorizationGuardComponent from "../authorization-guard/authorization-guard.component"
import CardNumericRaport from "../card-numeric-raport/card-numeric-raport.component"
import { ShowDataCardPropsType } from "../client-stats/client-stats.component"
import DonutPatternChart from "../donut-pattern-chart/donut-pattern-chart.component"
import VirtualizedList from "../list-raport/list-raport.component"

export type RangeDateTypeProps = {
    startDate: Date,
    endDate: Date
}

export type OverallStatsComponentPropsType = {
    rangeDate: RangeDateTypeProps;
    tabIndex: number
}

export type OverallRangeDateReqPropsType = {
    valNr: ShowDataCardPropsType,
    channelSellNr: ShowDataCardPropsType,
    avgValOrders: ShowDataCardPropsType,
    topAgents: ShowDataCardPropsType,
    topAgentsOnCentral: ShowDataCardPropsType,
    topOnCategories: ShowDataCardPropsType,
    canceledOrders: ShowDataCardPropsType,
    cashedOrders: ShowDataCardPropsType,
    unpaidOrders: ShowDataCardPropsType
}

const OverallStatsComponent = ({
    rangeDate,
    tabIndex
}: OverallStatsComponentPropsType) => {

    // var lineChart = {
    //     title: 'Product Trends by Month',
    //     subtitle: '(+43%) than last year',
    //     series: [
    //         {
    //             type: "bar",
    //             name: "Team A",
    //             data: [23, 11 , 22, 27, 13, 22, 37, 21, 44, 22, 30],
                
    //         },
    //         {
    //             type: "area",
    //             name: "Team B",
    //             data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
    //         },

    //         {
    //             type: "line",
    //             name: "Team C",
    //             data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],

    //         },
    //     ],
    //     options: {
    //         colors: [
    //             "#00ab55",
    //             "#FFE700",
    //             "#2D99FF",
    //         ],
    //         chart : {
    //             toolbar: {
    //                 show: false,
    //             }
    //         },
    //         stroke: {
    //             curve: 'smooth', 
    //         },
    //         grid: {
    //             row: {
    //                 colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
    //                 opacity: 0.5
    //             },
    //         },
    //         xaxis: {
    //             categories: ['Jan 03', 'Feb 03', 'Mar 03', 'Apr 03', 'May 03', 'Jun 03', 'Jul 03', 'Aug 03', 'Sep 03', "Oct 03", "Nov 03"],
    //         },
    //         plotOptions: {
    //             bar: {
    //                 borderRadius: 4,
    //                 columnWidth: '20%',
    //             }
    //         },
    //         fill: {
    //             type: ["", "gradient", ""],
    //             gradient: {
    //                     shade: "light",
    //                     type: "vertical",
    //                     inverseColors: false,
    //                     shadeIntensity: 1,
    //                     opacityFrom: 0.4,
    //                     opacityTo: 0.6,
    //                     stops: [0, 100]
    //                 },        
    //         }
    //     },
    // }

    const [showDataCard, setDataCard] = useState<OverallRangeDateReqPropsType>({
        valNr: {
            label: 'Valoare comenzi',
            value: '0'
        },
        channelSellNr: {
            label: 'Numar vanzari pe fiecare canal',
            value: {}
        },
        avgValOrders: {
            label: 'Valoarea medie a comenzilor',
            value: '0'
        },
        topAgents: {
            label: 'Top agenti / comenzi finalizate',
            value: ''
        },
        topAgentsOnCentral: {
            label: 'Top agenti / telefoane preluate',
            value: ''
        },
        topOnCategories: {
            label: 'Top categorii vandute',
            value: ''
        },
        canceledOrders: {
            label: 'Comenzi anulate',
            value: ''
        },
        cashedOrders: {
            label: 'Comenzi incasate',
            value: ''
        },
        unpaidOrders: {
            label: 'Comenzi neplatite',
            value: ''
        }
    })

    const [isLoading, setIsLoading] = useState<boolean>(true)


    useEffect(
        () => {

            async function loadingState() {
                setIsLoading(true)
                if(tabIndex === 0){
                    const response = await overallRangeDateReq(rangeDate)
                    setDataCard( 
                            (fields) => ({
                                valNr: {
                                    ...fields.valNr,
                                    value: response.valNr ?? '0'
                                    // ? `${response.valNr.toFixed(2)} ${CurrencyEnum.RON}` : '0'
                                },
                                channelSellNr: {
                                    ...fields.channelSellNr,
                                    value: response.channelSellNr
                                },
                                avgValOrders: {
                                    ...fields.avgValOrders,
                                    value: response.avgValOrders ?? '0'
                                    // ? `${response.avgValOrders.toFixed(2)} ${CurrencyEnum.RON}` : '0'
                                },
                                topAgents: {
                                    ...fields.topAgents,
                                    value: response.topAgents
                                },
                                topAgentsOnCentral: {
                                    ...fields.topAgentsOnCentral,
                                    value: response.topAgentsOnCentral
                                },
                                topOnCategories: {
                                    ...fields.topOnCategories,
                                    value: response.topOnCategories
                                },
                                canceledOrders:{
                                    ...fields.canceledOrders,
                                    value: response.canceledOrders
                                },
                                cashedOrders:{
                                    ...fields.cashedOrders,
                                    value: response.cashedOrders
                                },
                                unpaidOrders:{
                                    ...fields.unpaidOrders,
                                    value: response.unpaidOrders
                                },
                            }))
                    
                }
                setIsLoading(false)
            }

            loadingState()
        },
        [rangeDate, tabIndex]
    )

    // const pieChart = useMemo(
    //     () => {
    //         return {
    //             title: 'Numar vanzari pe fiecare canal',
    //             series: Object.values(showDataCard.channelSellNr.value),
    //             options: {
    //                 colors: 
    //                 [
    //                     "#00ab55",
    //                     "#2D99FF",
    //                     "#826AF9",
    //                 ],
    //                 labels: Object.keys(showDataCard.channelSellNr.value),
    //                 legend: {
    //                     position: "bottom",
    //                     fontSize: '15px',
    //                 },
    //                 tooltip: {
    //                     fillSeriesColor: false,
    //                     theme: 'dark',
    //                 },
                    
    //             },
    //         }
    //     },
    //     [showDataCard.channelSellNr]
    // )
    
    // const styleList = useMemo(
    //     () => {
    //         return {
    //             width: 400,
    //             height: 300
    //         }
    //     },
    //     []

    // )
    const styleListTwo = useMemo(
        () => {
            return {
                width: 688,
                height: 407
            }
        },
        []

    )
    // const styleBox = useMemo(
    //     () => {
    //         return {
    //             width: '25rem',
    //             height: '18.75rem'
    //         }
    //     },
    //     []
    // )
    const styleBoxTwo = useMemo(
        () => {
            return {
                width: '43rem',
                height: '23.44rem'
            }
        },
        []
    )

    const donutPatternChart = useMemo(
        () => {
            return {
                label: Object.keys(showDataCard.channelSellNr.value),
                value: Object.values(showDataCard.channelSellNr.value)
            }
        },
        [showDataCard]
    )

    const donutPatternChartCashedOrders = useMemo(
        () => {
            return {
                label: ['Card Credit', 'Cash'],
                value: Object.values(showDataCard.cashedOrders.value)
            }
        },
        [showDataCard]
    )

    const donutPatternChartUnpaidOrders = useMemo(
        () => {
            return {
                label: ['Neplatite Livrate', 'Neplatite Facturate'],
                value: Object.values(showDataCard.unpaidOrders.value)
            }
        },
        [showDataCard]
    )

    const cancelOrderNumber = useMemo(
        () => {
            return {
                label: 'Numar comenzi anulate',
                value: showDataCard.canceledOrders.value.canceledOrders
            }
        },
        [showDataCard]
    )

    const cancelOrderVal = useMemo(
        () => {
            return {
                label: 'Valoare comenzi anulate',
                value: showDataCard.canceledOrders.value.valCancelOrders
            }
        },
        [showDataCard]
    )

    return (
        <>
            {
                isLoading ? 
                    <LinearProgress style={{borderRadius: '10px'}} />
                    : 
                    <>
                        {
                            tabIndex === 0 ?
                                <div style={{display: "flex", flexWrap: 'wrap'}}>
                                    <div style={{
                                        display: 'flex', 
                                        justifyContent: 'space-between', 
                                        flexWrap: 'wrap', 
                                    }}>
                                        <CardNumericRaport details={showDataCard.valNr} price={true} />
                                        <AuthorizationGuardComponent roles={[RoleEnum.Admin]}>
                                            <CardNumericRaport details={showDataCard.avgValOrders} price={true} />
                                            <CardNumericRaport details={cancelOrderNumber} secondDetails={cancelOrderVal} price={true} />
                                        </AuthorizationGuardComponent>
                                    </div>
                                    <AuthorizationGuardComponent roles={[RoleEnum.Admin]}>
                                        <div style={{
                                        display: 'flex', 
                                        justifyContent: 'space-between', 
                                        flexWrap: 'wrap',
                                    }}>
                                        <DonutPatternChart details={donutPatternChart} generic={{label: showDataCard.channelSellNr.label}} />
                                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                            <VirtualizedList details={showDataCard.topOnCategories} styleList={styleListTwo} styleBox={styleBoxTwo} />
                                        </div>
                                        </div>
                                    </AuthorizationGuardComponent>
                                    <div style={{
                                        display: 'flex', 
                                        justifyContent: 'space-between', 
                                        flexWrap: 'wrap',
                                    }}>
                                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                            <VirtualizedList details={showDataCard.topAgents} styleList={styleListTwo} styleBox={styleBoxTwo} />
                                        </div>
                                        <AuthorizationGuardComponent roles={[RoleEnum.Admin]}>
                                            <DonutPatternChart details={donutPatternChartCashedOrders} generic={{label: showDataCard.cashedOrders.label}} />
                                        </AuthorizationGuardComponent>
                                    </div>

                                    <AuthorizationGuardComponent roles={[RoleEnum.Admin]}>
                                        <DonutPatternChart details={donutPatternChartUnpaidOrders} generic={{label: showDataCard.unpaidOrders.label}} />
                                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                            <VirtualizedList details={showDataCard.topAgentsOnCentral} styleList={styleListTwo} styleBox={styleBoxTwo} />
                                        </div>  
                                    </AuthorizationGuardComponent>
                                </div>
                                :
                                <>
                                </>
                        }
                    </>
            }
            
        </>
    )
}

export default OverallStatsComponent;