import styled from "styled-components";
import { themeVariables } from "../../theme/variables";

const ProductPanelComponentStyled = styled.div`

    .button-container {
        margin-top: 0.5rem;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1.5rem;
    }

    .product-name {
        color: gray;
        font-size: ${themeVariables.fontSizes.smaller};
    }

`;

export default ProductPanelComponentStyled;