import { Divider, Tooltip } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { LogEntityEnum } from "../../enums/log-entity.enum";
import { LogItemTypeEnum } from "../../enums/log-item-type.enum";
import { useStore } from "../../hooks/store.hook";
import { LogItemType, LogType } from "../../types/log.type";
import { getLogs } from "../../utils/requests";
import AvatarComponent from "../avatar/avatar.component";
import NoDataComponent from "../no-data/no-data.component";
import OrderHistoryHeaderComponent from "../order-history-header/order-history-header.component";
import OrderDetailsHistoryTabComponentStyled from "./order-details-history-tab.component.styled";

export type OrderDetailsHistoryTabComponentPropsType = {
    orderId?: string
}

const OrderDetailsHistoryTabComponent = ({
    orderId
}: OrderDetailsHistoryTabComponentPropsType) => {

    const uiStore = useStore('uiStore');

    const [history, setHistory] = useState<LogType>();
    
    const fetchHistory = useCallback(
        ({startDate, endDate}: { startDate?: Date, endDate?: Date }) => {
            uiStore.updatePanel({ isLoading: true });
            getLogs(LogEntityEnum.Orders, orderId ?? '', startDate, endDate)
                .then((logs: LogType) => {
                    setHistory(() => logs);
                })
                .catch((e: any) => {
                    toast.error(e.message);
                })
                .finally(() => {
                    uiStore.updatePanel({ isLoading: false });
                })
        },
        [orderId, uiStore]
    )

    const onDatesChange = useCallback(
        (newDates: { startDate?: Date, endDate?: Date }) => {
            fetchHistory(newDates);
        },
        [fetchHistory]
    )

    useEffect(
        () => {
            fetchHistory({});
        },
        [fetchHistory]
    )

    useEffect(
        () => {
            uiStore.updatePanel({
                subtitleComponent: (!history || !history.totalLogsCount) ? undefined : () => (
                    <OrderHistoryHeaderComponent
                        minDate={history?.startDate ?? new Date()}
                        maxDate={history?.endDate ?? new Date()}
                        onDatesChangeCB={onDatesChange}
                    />
                )
            })
        },
        [history, onDatesChange, uiStore]
    )

    const constructMessage = useCallback(
        (logItem: LogItemType): React.ReactNode => {
            const tokens = logItem.message.match(/{{[a-z]*}}/g);
            const otherTextTokens = logItem.message.split(/{{[a-z]*}}/);

            const rawAllTokens: { data: string, index: number, type: 'token' | 'normal' }[] = [
                ...(tokens ?? []).map(token => ({
                   data: token,
                   index: logItem.message.indexOf(token),
                   type: 'token' as 'token' | 'normal'
                })),
                ...otherTextTokens.map(token => ({
                    data: token,
                    index: logItem.message.indexOf(token),
                    type: 'normal' as 'token' | 'normal'
                 }))
            ]
                .sort((a, b) => a.index - b.index);

            return (
                <>
                    {
                        rawAllTokens.map((token, index) => {
                            if (token.type === 'normal') {
                                return <span key={index}>{token.data}</span>
                            }
                            else {
                                const dataToken = logItem.data.find(f => f.key === token.data.substring(2, token.data.length-2));
                                if (!dataToken) return <span key={index}></span>
                                
                                switch(dataToken.type as LogItemTypeEnum) {
                                    case LogItemTypeEnum.Emphasis:
                                        return <span key={index} className="log-item-emphasis">{dataToken.data}</span>
                                    case LogItemTypeEnum.Verb:
                                    case LogItemTypeEnum.Normal:
                                        return <span key={index}>{dataToken.data}</span>
                                    case LogItemTypeEnum.User:
                                        return <span key={index} className="log-item-user">{ isEmpty(dataToken.data) ? '' : '@'+dataToken.data }</span>
                                    default:
                                        return <span key={index}></span>
                                }
                            }
                        })
                    }
                </>
            )
        },
        []
    )

    const getDatePart = useCallback(
        (date: string): string => {
            return date.split(' ').slice(0, 3).join(' ');
        },
        []
    )

    const getTimePart = useCallback(
        (date: string): string => {
            return date.split(' ')[date.split(' ').length-1];
        },
        []
    )


    const renderItem = useCallback(
        (item: LogItemType, index: number, items: LogItemType[]) => {
            return (
                <div className="log-item-wrapper" key={index}>
                    <div className="date-section">
                        <div>{getDatePart(item.createdAt)}</div>
                        <div>{getTimePart(item.createdAt)}</div>
                    </div>

                    <div className="icon-section">
                        <div className="line-section" style={{visibility: index > 0 ? 'visible' : 'hidden'}}></div>
                        <Tooltip title={item.user?.fullName ?? 'Fara utilizator'}>
                            <div className="avatar-section">
                                <AvatarComponent name={item.user?.fullName ?? ''} size="2.4rem" />
                            </div>
                        </Tooltip>
                        <div className="line-section" style={{visibility: index < items.length-1 ? 'visible' : 'hidden'}}></div>
                    </div>

                    <div className="content-section">
                        {
                            item.user ? 
                                <>
                                    <div className="content-user">
                                        @{item.user.displayName}
                                    </div>
                                    <Divider style={{marginBottom: '0.3rem'}} />
                                </> :
                                ''
                        }
                        <div className="content-message">
                            {constructMessage(item)}
                        </div>
                    </div>
                    
                </div>
            )
        },
        [constructMessage, getDatePart, getTimePart]
    )
    
    /** define the return statement bellow */
    return (
        <OrderDetailsHistoryTabComponentStyled>
            {
                (history?.items ?? []).map(renderItem)
            }
            {
                (history && isEmpty(history?.items)) ?
                <NoDataComponent message="Fara istoric de actiuni" /> :
                ''
            }
        </OrderDetailsHistoryTabComponentStyled>
    )

}

export default OrderDetailsHistoryTabComponent;