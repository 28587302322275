import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface FilterSelectPropsType {
  options: {
    label: string;
    value: any;
  }[],
  selectFilterValue: any,
  placeholder: string,
  onValueChange: (newValue: string) => void,
  customStyle?: {
    marginRight: string;
    marginBottom: string;
  }
}

const BasicSelect = ({
  options, 
  selectFilterValue, 
  onValueChange,
  placeholder,
  customStyle
}:FilterSelectPropsType) => {

  const handleChange = React.useCallback(
    (event: SelectChangeEvent) => {
      onValueChange(event.target.value as any);
    },
    [onValueChange]
  ) 

  return (
    <Box  sx = {{
        display: "flex",
        flex: 1,
        marginRight: customStyle ? customStyle.marginRight : "0.625rem",
        marginBottom: customStyle ? customStyle.marginBottom : '0'
    }}>
      <FormControl 
        fullWidth
      >
        <InputLabel sx={{fontFamily: 'Poppins, sans-serif !important'}} id="Cauta">{placeholder}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="Cauta"
          value={selectFilterValue}
          label={placeholder}
          onChange={handleChange}
          sx = {{
              borderRadius: "0.625rem",
              fontFamily: 'Poppins, sans-serif !important'
          }}
        >
          {options.map( (value, index) => {
            return (
              <MenuItem 
                key={index} 
                sx={{fontFamily: 'Poppins, sans-serif !important'}} 
                value={value.value}
              >
                {value.label}
              </MenuItem>
            )
          })} 
          
        </Select>
      </FormControl>
    </Box>
  );
}

export default BasicSelect;
