import styled from "styled-components";
import { themeVariables } from "../../theme/variables";

const UserPanelComponentStyled = styled.div`

    .button-container {
        margin-top: 0.5rem;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1.5rem;
    }

    .role-chip {
        margin-right: 0.5rem;
        margin-bottom: 0.2rem;
    }

    .roles-error-message {
        color: ${themeVariables.semanticColors.error};
        font-weight: ${themeVariables.fontWeights.regular};
        line-height: 1.66;
        margin-left: 14px;
        font-size: ${themeVariables.fontSizes.xSmall};
    }

`;

export default UserPanelComponentStyled;