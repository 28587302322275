import { Grid, InputAdornment } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { CurrencyEnum } from "../../enums/currency.enum";
import { TableTypeEnum } from "../../enums/table-type.enum";
import { useStore } from "../../hooks/store.hook";
import { StateFieldType } from "../../types/form.types";
import { StockLinesType  } from "../../types/product.type";
import { updateStockLine } from "../../utils/requests";
import ButtonComponent from "../button/button.component";
import StockSelectComponent from "../stock-select/stock-select.component";
import TextFieldComponent from "../text-field/text-field.component";
import { StockLinesComponentStyled } from "./stock-lines.component.styled";

type StateType = {
    fields: {
        id: StateFieldType<string>;
        description: StateFieldType<string>;
        stock: StateFieldType<string>;
        price: StateFieldType<string>;
    };
    shouldDisplayError: boolean;
}

type StockLinesPropsType = {
    stockLine?: StockLinesType;
    productId: string;
}

const StockLines = ({
    stockLine,
    productId,
}: StockLinesPropsType) => {

    const uiStore = useStore('uiStore');
    const tableStore = useStore('tableStore');
    const [isLoading, setIsLoading] = useState(false);

    const [state, setState] = useState<StateType>({
        fields: {
            id: {
                value: '',
                isValid: true,
                noValidation: true
            },
            description: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati o descriere',
            },
            price: {
                value: '0',
                isValid: false,
                errorMessage: 'Furnizati un pret valid pentru produs',
                validator: (newValue: string) => Number(newValue) > 0
            },
            stock: {
                value: '0',
                isValid: false,
                errorMessage: 'Furnizati un pret valid pentru produs',
                validator: (newValue: string) => Number(newValue) > 0
            },
        },
        shouldDisplayError: false
    });

    const isEdit = useMemo(
        (): boolean => {
            return !!productId;
        },
        [productId]
    )

    const updateStateField = useCallback(
        (currentField: StateFieldType<any>, newValue: any): StateFieldType<any> => {
            return {
                ...currentField,
                isValid: (currentField.validator ? (currentField.validator as any)(newValue) : !!newValue) || 
                    currentField.noValidation,
                value: newValue
            }
        },
        []
    )

    const updateState = useCallback(
        <T extends keyof typeof state.fields>(field: T, newValue: any) => {
            setState(state => ({
                ...state,
                fields: {
                    ...state.fields,
                    [field]: updateStateField(state.fields[field], newValue)
                }
            }))
        },
        [state, updateStateField]
    )

    useEffect(
        () => {
                if(stockLine) {
                    uiStore.updatePanel({ title: 'Editare linie stoc' });
                    setState(state => ({
                        ...state,
                        fields: {
                            id: updateStateField(state.fields.id, stockLine.id),
                            description: updateStateField(state.fields.description, stockLine.description),
                            stock: updateStateField(state.fields.stock, stockLine.stock),
                            price: updateStateField(state.fields.price, stockLine.price),
                        }
                    }))
                } else {
                    uiStore.updatePanel({ title: 'Adaugare linie stoc' });
                }
            
        },
        [uiStore, productId, stockLine, updateStateField]
    )
   
    const stockLinesAction = useCallback (
        async () => {
            setState(state => ({
                ...state,
                shouldDisplayError: true
            }));
            const isNotValid = Object.values(state.fields).some(field => !field.isValid);
            if (isNotValid) return;

            var newStockLine: StockLinesType = {
                id: state.fields.id.value,
                description: state.fields.description.value,
                price: state.fields.price.value,
                stock: state.fields.stock.value,
            }
    
            setIsLoading(() => true);
            try {
                /** edit the product */
                await updateStockLine(productId, newStockLine, false)

                toast.success('Stocul a fost salvat cu succes');
                uiStore.dismissPanel();
                tableStore.updateTable(TableTypeEnum.Products)
            } catch(e: any) {
                toast.error(e.message);
            }
            setIsLoading(() => false);
        },
        [state, productId, uiStore, tableStore]
    )

    return (
        <StockLinesComponentStyled>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <TextFieldComponent
                        label="Descriere" 
                        variant="outlined" 
                        fullWidth={true}
                        multiline
                        rows={3}
                        value={state.fields.description.value}
                        error={state.shouldDisplayError && !state.fields.description.isValid}
                        helperText={state.shouldDisplayError && !state.fields.description.isValid && state.fields.description.errorMessage}
                        onTextChange={e => updateState('description', e)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextFieldComponent
                        type='number'
                        label="Pret achizitie" 
                        variant="outlined" 
                        fullWidth={true}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{CurrencyEnum.RON}</InputAdornment>
                        }}
                        value={state.fields.price.value}
                        error={state.shouldDisplayError && !state.fields.price.isValid}
                        helperText={state.shouldDisplayError && !state.fields.price.isValid && state.fields.price.errorMessage}
                        onTextChange={e => updateState('price', e)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <StockSelectComponent
                        type='number'
                        label="Stoc"
                        variant="outlined"
                        value={(state.fields.stock.value ?? '0').toString()}
                        onTextChange={e => updateState('stock', e)}
                        error={state.shouldDisplayError && !state.fields.stock.isValid}
                        helperText={state.shouldDisplayError && !state.fields.stock.isValid && state.fields.stock.errorMessage}
                        fullWidth={true}
                    />
                </Grid>

                </Grid>

                <div className="button-container">
                    <ButtonComponent onClick={stockLinesAction} isLoading={isLoading}>
                        { isEdit ? 'Salveaza' : 'Creeaza' }
                    </ButtonComponent>
                </div>
        </StockLinesComponentStyled>
        
    )
}

export default StockLines