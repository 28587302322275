import React, { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { OrderType } from "../../types/order.type";
import { getOrder } from "../../utils/requests";
import OrderDetailsComponentStyled from "./order-details.component.styled";
import { useStore } from "../../hooks/store.hook";
import { History, ListAlt} from "@mui/icons-material";
import { OrderStatusEnum } from "../../enums/order-status.enum";
import OrderStatusBadgeComponent from "../order-status-badge/order-status-badge.component";
import OrderDetailsSummaryTabComponent from "../order-details-summary-tab/order-details-summary-tab.component";
import OrderDetailsHistoryTabComponent from "../order-details-history-tab/order-details-history-tab.component";
import OrderPanelComponent from "../order-panel/order-panel.component";
import { PanelType } from "../../enums/panel-type.enum";
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip } from "@mui/material";
import { PanelActionItem } from "../../stores/ui.store";
import { isEmpty } from "lodash";
import AvatarComponent from "../avatar/avatar.component";
import { UserType } from "../../types/user.type";
import OrderAssignUserComponent from "../order-assign-user/order-assign-user.component";
import { TableTypeEnum } from "../../enums/table-type.enum";

enum OrderDetailsTab {
    OrderDetails = 0,
    ActionLog = 1
}

export type OrderDetailsComponentPropsType = {
    orderId: string;
}

const OrderDetailsComponent = ({
    orderId
}: OrderDetailsComponentPropsType) => {

    const uiStore = useStore('uiStore');
    const tableStore = useStore('tableStore');
    const userStore = useStore('userStore');

    const [orderDetails, setOrderDetails] = useState<OrderType>();
    const [tabIndex, setTabIndex] = useState<number>(0);

    const openEditPanel = useCallback (
        () => [
            uiStore.openPanel({
                key: PanelType.EditOrder,
                title: 'Editeaza comanda',
                component: <OrderPanelComponent orderDetail={orderDetails} /> ,
                panelWidth: '700px',
                onDismiss: (data: OrderType) => {
                    if (!data) return;
                    setOrderDetails(data)
                    uiStore.resetOrderData()
                }
            })
        ],
        [orderDetails, uiStore]
    )

    const actionItems = useMemo(
        (): PanelActionItem[] => {
            let actionItems: PanelActionItem[] = [{
                component: (
                    <Tooltip title={isEmpty((orderDetails?.assignedUser as UserType | undefined)?.fullName) ? 'Neasignat' : (orderDetails?.assignedUser as UserType | undefined)?.fullName as string}>
                        <div>
                            <AvatarComponent
                                clickable 
                                onClick={() => {
                                    uiStore.openPanel({
                                        title: 'Asigneaza utilizator',
                                        component: <OrderAssignUserComponent orderDetails={orderDetails as OrderType} />,
                                        key: PanelType.OrderAssignUser,
                                        onDismiss: (updatedOrder?: OrderType) => {
                                            if (!updatedOrder) return;
                                            setOrderDetails(() => updatedOrder);
                                            tableStore.updateTable(TableTypeEnum.Orders)
                                        }
                                    })
                                }} 
                                name={(orderDetails?.assignedUser as UserType | undefined)?.fullName ?? ''} 
                                size="2.2rem" 
                            />
                        </div>
                    </Tooltip>
                )
            }]

            if (orderDetails?.status === OrderStatusEnum.Draft && !orderDetails.awbs?.length) {
                actionItems.push({
                    component: (
                        <Tooltip title="Editeaza">
                            <IconButton
                                onClick={openEditPanel}
                                size="small"
                            >
                                <EditIcon />                                
                            </IconButton>
                        </Tooltip>
                    )
                })
            }

            return actionItems;
        },
        [orderDetails, openEditPanel, tableStore, uiStore]
    )

    useEffect(
        () => {
            
            uiStore.updatePanel({
                title: `Comanda #${orderDetails?.orderId ?? ''}`,
                tabs: [
                    {
                        label: 'Detalii comanda',
                        icon: <ListAlt />
                    },
                    {
                        label: 'Istoric actiuni',
                        icon: <History />,
                    }
                ],
                tabIndex: tabIndex,
                onTabChange: (newValue) => setTabIndex(() => newValue),
                additionalTitle: <OrderStatusBadgeComponent status={orderDetails?.status ?? OrderStatusEnum.Draft} />,
                actionItems: actionItems
            });
        },
        [
            orderDetails, 
            actionItems, 
            tabIndex, 
            uiStore, 
            userStore.user?.roles
        ]
    )

    useEffect(
        () => {
            getOrder(orderId)
                .then((order: OrderType) => {
                    setOrderDetails(() => order);
                })
                .catch((e: any) => {
                    toast.error(e.message);
                })
        },
        [orderId]
    )

    /** define the return logic bellow */

    return (
        <OrderDetailsComponentStyled>

            {
                tabIndex === OrderDetailsTab.OrderDetails ?
                    <OrderDetailsSummaryTabComponent 
                        onOrderDetailsChange={setOrderDetails} 
                        orderDetails={orderDetails} 
                    /> : 
                    <OrderDetailsHistoryTabComponent
                        orderId={orderDetails?.id}
                    />
            }

        </OrderDetailsComponentStyled>
    )

}

export default OrderDetailsComponent;