import { Chip } from "@mui/material";
import React from "react";
import { useStore } from "../../hooks/store.hook";
import AvatarComponent from "../avatar/avatar.component";
import UserListItemComponentStyled from "./user-list-item.component.styled";

export type UserListItemComponentPropsType = {
    fullName: string;
    id: string;
    email: string;
    withSelfChip?: boolean;
    selfChipPosition?: 'title' | 'end';
}

const UserListItemComponent = ({
    fullName,
    id,
    email,
    withSelfChip,
    selfChipPosition = 'end'
}: UserListItemComponentPropsType) => {

    const userStore = useStore('userStore');

    /** define the return statement bellow */
    return (
        <UserListItemComponentStyled>
            <div className="avatar-icon">
                <AvatarComponent name={fullName} size="2.4rem" />
            </div>
            <div className="content">
                <div className="content-title">
                    {fullName}
                    {
                        (withSelfChip && selfChipPosition === 'title' && id === userStore.user?.id) ?
                        <Chip
                            style={{marginLeft: '0.5rem'}}
                            size="small"
                            label="Tu"
                        /> :
                        ''
                    }
                </div>
                <div className="content-subtitle">
                    {email}
                </div>
            </div>
            {
                (withSelfChip && selfChipPosition === 'end' && id === userStore.user?.id) ?
                <div>
                    <Chip
                        size="small"
                        label="Tu"
                    />
                </div> :
                ''
            }
        </UserListItemComponentStyled>
    )

}

export default UserListItemComponent;