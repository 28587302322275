import styled from "styled-components";

export const ListComponentStyled = styled.div`

.button-container {
    margin-top: 0.5rem;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.5rem;
}

`