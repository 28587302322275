import { Autocomplete, AutocompleteRenderOptionState, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getRequest } from "../../utils/http-requests";
import TextFieldComponent from "../text-field/text-field.component";
import AsyncSelectComponentStyled from "./async-select.component.styled";

export type AsyncSelectComponentPropsType = {
    options?: any[],
    placeholder?: string;
    url?: string | undefined;
    value?: AsyncSelectOptionType | null | undefined;
    label?: string;
    error?: boolean;
    renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: AsyncSelectOptionType, state: AutocompleteRenderOptionState) => React.ReactNode,
    getOptionLabel?: (option: AsyncSelectOptionType) => string,
    onOptionChange?: (newValue: AsyncSelectOptionType | null) => void,
    isOptionEqualToValue?: ((option: AsyncSelectOptionType, value: AsyncSelectOptionType) => boolean) | undefined,
    getOptions?: (options: any) => void,
}

export type AsyncSelectOptionType = {
    id?: string;
    [prop: string]: any;
}

const AsyncSelectComponent = ({
    placeholder,
    url,
    value,
    label,
    error,
    options,
    renderOption,
    getOptionLabel,
    onOptionChange = () => { },
    isOptionEqualToValue,
}: AsyncSelectComponentPropsType) => {

    const [optionsFromComp, setOptions] = useState<AsyncSelectOptionType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(
        () => {
            async function getOption(){
                if (!url) return;
                setIsLoading(true)
                try{
                    const response = await getRequest({url: url})
                    setOptions(response.data);
                } catch(e: any) {
                    toast.error(e.message)
                }
                setIsLoading(false)
            }
            getOption()
        },
        [url]
    )
    
    useEffect(
        () => {
            if(!options) return;

            const myInterval = setInterval(myTimer, 50);
            let c = 0 
            function myTimer() {
                if(c === 1){
                    clearInterval(myInterval);
                    if(!options) return;
                    setOptions(options)
                    setIsLoading(false)
                }
                c = c + 1
            }
        },
        [options]
    )

    /** define the return logic bellow */
    return (
        <>
            {
                isLoading ? 
                <LinearProgress style={{borderRadius: '10px'}} />
                : 
                <AsyncSelectComponentStyled>
                    <Autocomplete
                        options={url ? optionsFromComp : options ? options : []}
                        renderOption={renderOption}
                        getOptionLabel={getOptionLabel}
                        value={ value?.id ? {id: value?.id, ...value} : value }
                        onChange={(_, newOption) => onOptionChange(newOption)}
                        id="combo-box-demo"
                        disablePortal
                        defaultChecked={true}
                        renderInput={(params) => <TextFieldComponent 
                            {...params} 
                            error={error} 
                            helperText={error ? `Optiunea nu a fost gasita` : ''}
                            placeholder={placeholder} 
                            label={label} 
                        />}
                        isOptionEqualToValue={isOptionEqualToValue}
                    />
                </AsyncSelectComponentStyled>
            }
        </>
    )

}

export default AsyncSelectComponent;