import styled from "styled-components";
import { themeVariables } from "../../theme/variables";

const InssuficientStockErrorComponentStyled = styled.div`

    .product-name {
        color: gray;
        font-size: ${themeVariables.fontSizes.smaller};
    }

    .log-stock {
        color: gray;

        &.separator {
            margin-left: 0.3rem;
            margin-right: 0.3rem;
        }

        &.available {
            color: ${themeVariables.semanticColors.success};
        }

        &.needed {
            color: ${themeVariables.semanticColors.error};
        }
    }

    .log-deficit {
        color: ${themeVariables.semanticColors.error};
        font-weight: ${themeVariables.fontWeights.semibold};
        font-size: ${themeVariables.fontSizes.normal};
    }

`;

export default InssuficientStockErrorComponentStyled;