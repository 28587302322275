import { ArrowCircleDown, CancelOutlined, IosShare, Tag, Visibility } from "@mui/icons-material";
import React from "react";
import OrderDetailsComponent from "../../../../components/order-details/order-details.component";
import OrderPanelComponent from "../../../../components/order-panel/order-panel.component";
import OrderStatusBadgeComponent from "../../../../components/order-status-badge/order-status-badge.component";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableActionsPropsType } from "../../../../components/table/table.component";
import { OrderStatusEnum } from "../../../../enums/order-status.enum";
import { PanelType } from "../../../../enums/panel-type.enum";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import { useStore } from "../../../../hooks/store.hook";
import OrdersPageStyled from "./orders.page.styled";
import { OrderType, TableOrderType } from '../../../../types/order.type';
import { isEmpty } from "lodash";
import { DataSourceEnum } from "../../../../enums/data-source.enum";
import { assignOrderUser, cancelOrder, getOrder, issueOrder } from "../../../../utils/requests";
import toast from "react-hot-toast";
import AssignAwbComponent from "../../../../components/assign-awb/assign-awb.component";
import { DialogResponseTypeEnum } from "../../../../enums/dialog-response-type.enum";
import { OrderAssignTypeEnum } from "../../../../enums/order-assign-type.enum";
import AvatarComponent from "../../../../components/avatar/avatar.component";
import { Tooltip } from "@mui/material";
import OrderAssignUserComponent from "../../../../components/order-assign-user/order-assign-user.component";

const OrdersPage = () => {

    const uiStore = useStore('uiStore');
    const tableStore = useStore('tableStore');
    const userStore = useStore('userStore');

    const buttons = [
        {
            text: "Creeaza o comanda",
            fOnClick: () => {
                uiStore.openPanel({
                    key: PanelType.AddOrder,
                    component: <OrderPanelComponent />,
                    panelWidth: '700px',
                    title: 'Creeaza o comanda'
                });
            }
        },
    ]

    var actionItems: ActionItemPropsType[] = [
        {
            text: "Vezi detalii",
            icon: <Visibility/>,
            color: "black",
            fOnClick: (row: TableOrderType) => {
                uiStore.openPanel({
                    key: PanelType.ViewOrder,
                    component: <OrderDetailsComponent orderId={row.id} />,
                    title: 'Detalii comanda',
                    panelWidth: '700px'
                });
            }
        },
        {
            text: "Emite factura",
            icon: <IosShare />,
            color: "black",
            canDisplay: (row: TableOrderType) => [OrderStatusEnum.Draft, OrderStatusEnum.AwbGenerated].includes(row.status) && !row?.invoice ,
            fOnClick: (row: TableOrderType, { setLoading }) => {
                setLoading(true);
                issueOrder(row.id)
                    .then(() => {
                        tableStore.updateTable(TableTypeEnum.Orders);
                        toast.success('Factura a fost emisa cu succes');
                    })  
                    .catch((e: any) => {
                        toast.error(e.message);
                    })
                    .finally(() => setLoading(false));
            }
        },
        {
            text: "Genereaza AWB",
            icon: <Tag />,
            color: "black",
            canDisplay: (row: TableOrderType) => [OrderStatusEnum.Draft, OrderStatusEnum.Issued].includes(row.status) ,
            fOnClick: (row: TableOrderType, { setLoading }) => {
                setLoading(true);
                getOrder(row.id)
                    .then((order: OrderType) => {
                        if (!order.invoice && !order.assignAwbWithoutInvoiceReason) {
                            uiStore.openDialog({
                                title: 'Generare AWB',
                                message: 'Esti sigur ca vrei sa generezi AWB fara sa ai factura emisa pentru aceasta comanda?',
                                withReason: true
                            })
                                .then(resp => {
                                    if (resp.value !== DialogResponseTypeEnum.Confirm) return;
                                    uiStore.openPanel({
                                        key: PanelType.AssignAwb,
                                        component: <AssignAwbComponent 
                                            orderDetails={order} 
                                            assignAwbWithoutInvoiceReason={resp.reason} 
                                        />,
                                        title: 'Genereaza AWB',
                                        panelWidth: '650px',
                                        onDismiss: (data) => {
                                            if (!data) return;
                                            tableStore.updateTable(TableTypeEnum.Orders);
                                        }
                                    });
                                })
                        } else {
                            uiStore.openPanel({
                                key: PanelType.AssignAwb,
                                component: <AssignAwbComponent orderDetails={order} />,
                                title: 'Genereaza AWB',
                                panelWidth: '650px',
                                onDismiss: (data) => {
                                    if (!data) return;
                                    tableStore.updateTable(TableTypeEnum.Orders);
                                }
                            });
                        }
                    })
                    .catch((e: any) => {
                        toast.error(e.message);
                    })
                    .finally(() => setLoading(false));
            }
        },
        {
            text: "Anuleaza",
            icon: <CancelOutlined />,
            color: "black",
            canDisplay: (row: TableOrderType) => [OrderStatusEnum.Issued].includes(row.status) && isEmpty(row?.awbs) ,
            fOnClick: (row: TableOrderType, { setLoading }) => {
                setLoading(true);
                cancelOrder(row.id)
                    .then(() => {
                        tableStore.updateTable(TableTypeEnum.Orders);
                        toast.success('Comanda a fost anulata cu succes');
                    })  
                    .catch((e: any) => {
                        toast.error(e.message);
                    })
                    .finally(() => setLoading(false));
            }
        },
        {
            text: "Asigneaza-ma",
            icon: <ArrowCircleDown />,
            color: "black",
            canDisplay: (row: TableOrderType) => row.assignedUser?.id !== userStore?.user?.id ,
            fOnClick: (row: TableOrderType, { setLoading }) => {
                setLoading(true);
                assignOrderUser(row.id, userStore?.user?.id)
                    .then(() => {
                        tableStore.updateTable(TableTypeEnum.Orders);
                        toast.success('Comanda ti-a fost asignata');
                    })  
                    .catch((e: any) => {
                        toast.error(e.message);
                    })
                    .finally(() => setLoading(false));
            }
        },
    ]

    const customRenderer: CustomRendererPropsType = {
        status: (row: TableOrderType) => <OrderStatusBadgeComponent status={row.status} />,
        customer: (row: TableOrderType) => (
            <>
                <div className="order-customer">{row.customer}</div>
            </>
        ),
        assignedUser: (row: TableOrderType, tableActions: TableActionsPropsType) => (
            <div className="assigned-user-wrapper">
                <Tooltip title={isEmpty(row.assignedUser?.fullName) ? 'Neasignat' : row.assignedUser?.fullName as string}>
                    <div>
                        <AvatarComponent 
                            clickable 
                            onClick={() => {
                                tableActions.setLoading(true);
                                getOrder(row.id)
                                    .then(orderDetails => {
                                        uiStore.openPanel({
                                            title: 'Asigneaza utilizator',
                                            component: <OrderAssignUserComponent orderDetails={orderDetails as OrderType} />,
                                            key: PanelType.OrderAssignUser,
                                            onDismiss: (updatedOrder?: OrderType) => {
                                                if (!updatedOrder) return;
                                                tableStore.updateTable(TableTypeEnum.Orders)
                                            }
                                        })
                                    })
                                    .catch((e: any) => toast.error(e.message))
                                    .finally(() => {
                                        tableActions.setLoading(false);
                                    });
                            }} 
                            name={row.assignedUser?.fullName ?? ''} 
                            size="2.2rem" 
                        />
                    </div>
                </Tooltip>
            </div>
        )
    }

    /** define the state bellow */
    return (
        <OrdersPageStyled>
            <PageTemplateComponent title="Comenzi" buttons={buttons}>
                <TableComponent
                    tableKey={TableTypeEnum.Orders} 
                    url="/order"
                    searchBarPlaceholder="Cauta dupa client sau id comanda"
                    dropdownPlaceholder="Status"
                    secondDropdownPlaceholder="Utilizator asignat" 
                    withoutSelect
                    dropdownOptions={Object.values(OrderStatusEnum).map(status => ({ label: status, value: status }))}
                    secondDropdownOptions={Object.values(OrderAssignTypeEnum).map(status => ({ label: status, value: status }))}
                    actionItems={actionItems}
                    tabs={Object.values(DataSourceEnum).map(source => ({ label: source, value: source }))}
                    customRenderer={customRenderer}
                />
            </PageTemplateComponent>
        </OrdersPageStyled>
    )

}

export default OrdersPage;