export enum TableTypeEnum {
    Users = 'users',
    Customers = 'customers',
    Products = 'products',
    Orders = 'orders',
    SelectOrderProducts = 'select-order-products',
    OrderProducts = 'order-products',
    ViewOrderItems = 'view-order-items',
    AssignAwbTable = 'assign-awb-table',
    ProductChildProducts = 'product-child-products',
    ProductsLineItems = 'products-line-items',
    InsufficientStock = 'inssuficient-stock'
}