import styled from "styled-components";
import { themeVariables } from "../../theme/variables";

const OrderPanelComponentStyled = styled.div`

    .customer-info {
        width: 100%;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.25);
        padding: 0.9rem 1rem;
        box-sizing: border-box;

        .body-section {
            display: flex;
            align-items: center;

            .left-section {
                margin-right: 1rem;
            }

            .right-section {
                flex: 1;

                .customer-description {
                    color: gray;
                    font-size: ${themeVariables.fontSizes.smaller};

                    span {
                        margin-right: 0.4rem;
                    }
                }
            }
        }
    }

    .button-section {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: -0.5rem;
    }

    .product-name {
        color: gray;
        font-size: ${themeVariables.fontSizes.smaller};
    }

    .button-container {
        margin-top: 0.5rem;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1.5rem;
    }

    .button-inline {
        display: flex;
        justify-content: flex-begin;
    }

    .price-without-discount {
        position: relative;

        .line-throught {
            position: absolute;
            background: gray;
            height: 1px;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        color: gray;
        font-size: ${themeVariables.fontSizes.smaller};
        text-decoration: line-through;
    }

    .total-price-wrapper {
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        &.child-wrapper {
            margin-right: 4.5rem;
        }
    }

`;

export default OrderPanelComponentStyled;