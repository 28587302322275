import { Close, FilePresent } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { ContentTypeEnum } from "../../enums/content-type.enum";
import DropzoneUploadComponentStyled from "./dropzone-upload.component.styled";
import toast from 'react-hot-toast';

export type DropzoneUploadComponentPropsType = {
    onFileChange: (file: File | null) => void
}

const DropzoneUploadComponent = ({
    onFileChange
}: DropzoneUploadComponentPropsType) => {

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const inputRef = useRef(null);

    const _isExtensionValid = (file: File): boolean => {
        return [ContentTypeEnum.Xlsx, ContentTypeEnum.Xls].includes(file.type as any);
    }

    const onInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!event.target?.files?.length) return;
            const file = event.target.files[0];
            if (!_isExtensionValid(file)) {
                toast.error('Fisier neacceptat. Doar .xlsx si .xls sunt acceptate');
                return;
            }

            setSelectedFile(() => file);
            onFileChange(file);
        },
        [onFileChange]
    ) 

    const onWrapperClick = useCallback(
        () => {
            if (!inputRef.current) return;
            (inputRef.current as any).click?.();
        },
        []
    )

    const onFileDrop = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
            if (!event.dataTransfer?.files?.length) return;
            const file = event.dataTransfer.files[0];
            if (!_isExtensionValid(file)) {
                toast.error('Fisier neacceptat. Doar .xlsx si .xls sunt acceptate');
                return;
            }

            setSelectedFile(() => file);
            onFileChange(file);
        },
        [onFileChange]
    )

    const onRemoveSelectedFile = useCallback(
        () => {

            /** we do this so we can reupload the same file (might not work in all the major browsers. Only tested in Microsoft Edge) */
            if (inputRef.current) {
                (inputRef.current as any).value = ''
            }

            setSelectedFile(() => null);
            onFileChange(null);
        },
        [onFileChange]
    )

    const fileSize = useMemo(
        (): string => {
            if (!selectedFile) return '';

            if (selectedFile.size > 1000 * 1024)
                return `${(selectedFile.size/(1000*1024)).toFixed(2)} MB`;
            else if (selectedFile.size > 1024)
                return `${(selectedFile.size/1024).toFixed(2)} KB`;
            
            return `${(selectedFile.size).toFixed(2)} bytes`;
        },
        [selectedFile]
    )


    /** define the return statement bellow */
    return (
        <DropzoneUploadComponentStyled>
            <div className="dropzone-inner" onClick={onWrapperClick} onDrop={onFileDrop}>
                <input type="file" className="image-upload-input" ref={inputRef} onChange={onInputChange} accept={`${ContentTypeEnum.Xlsx}, ${ContentTypeEnum.Xls}`} />

                <FilePresent className="message-icon" />
                <div className="message-title">Selecteaza un fisier xlsx sau xls pentru import</div>
                <div className="message-subtitle">sau trage și plaseaza-l aici</div>
            </div>

            {
                selectedFile ?
                    <div className="file-selected-wrapper">
                        <FilePresent />
                        <div className="file-selected-content">
                            <div className="file-selected-content-title">
                                {selectedFile.name}
                            </div>
                            <div className="file-selected-content-subtitle">
                                {fileSize}
                            </div>
                        </div>
                        <div className="file-selected-action">
                            <IconButton onClick={onRemoveSelectedFile}>
                                <Close />
                            </IconButton>
                        </div>
                    </div> :
                    ''
            }

        </DropzoneUploadComponentStyled>
    )

}

export default DropzoneUploadComponent;