import { TableStore } from "./table.store";
import { UiStore } from "./ui.store";
import { UserStore } from "./user.store";
import { WebsocketStore } from "./websocket.store";

export class RootStore {
    uiStore: UiStore;
    userStore: UserStore;
    tableStore: TableStore;
    websocketStore: WebsocketStore
    
    constructor() {
        this.uiStore = new UiStore(this);
        this.userStore = new UserStore(this);
        this.tableStore = new TableStore(this);
        this.websocketStore = new WebsocketStore(this);
    }
}