import styled from "styled-components";

const ProductsExportPanelComponentStyled = styled.div`

    .button-container {
        margin-top: 2rem;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1.5rem;
    }

`;

export default ProductsExportPanelComponentStyled;