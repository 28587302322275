import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { themeVariables } from '../../theme/variables'
import BasicSelect from '../dropdown-table-component/dropdown-table-component.component';
import SearchAppBar from '../search-bar-table/search-bar-table.component';
import {DropdownSearchBarStyled, FooterTableStyled, TableComponentStyled} from './table.component.styled';
import CenteredTabs from '../tabs-table/tabs-table.component';
import { getRequest } from '../../utils/http-requests'
import { Chip, Collapse, Divider, LinearProgress, TableFooter } from '@mui/material';
import { cloneDeep, isEmpty } from 'lodash';
import { HeaderTypeEnum } from '../../enums/header-type.enum';
import TableActionMenu from '../table-action-menu/table-action-menu.component';
import { TableTypeEnum } from '../../enums/table-type.enum';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/store.hook';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import NoDataComponent from '../no-data/no-data.component';
interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedState: SelectedStatePropsType;
  values: any[];
  actionItems: any[] | undefined;
  totalNumberOfElements: number;
  withoutSelect?: boolean;
  withExpandableRows?: boolean,
  isLoading: boolean;
  numberOfCells: number;
  withItems: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, selectedState, values, actionItems, totalNumberOfElements, withoutSelect, withExpandableRows, isLoading, numberOfCells, withItems} =
    props;

  const renderCell = (header: HeadersPropsType, index: number) => {
    return (
      <TableCell
        align={header.alignment}
        key={index}
        style={{width: header.width ?? 'unset'}}
      >
        {header.label}
      </TableCell>
    )
  }

  return (
    <TableHead>
        {numSelected > 0 ? (
          <TableRow
            sx={{
              backgroundColor: themeVariables.colors.primaryLight,
            }}
            >

          <TableCell
            align="center"
            style={{ width: '20px' }}
            padding="checkbox"
          >
            <Checkbox
              className='MuiButtonBase-root-MuiCheckbox-root'
              color="primary"
              indeterminate={selectedState.selectedItems.length < totalNumberOfElements && selectedState.selectedItems.length > 0}
              checked={selectedState.isAllSelected || selectedState.selectedItems.length === totalNumberOfElements}
              onChange={onSelectAllClick}
            />
          </TableCell>

          {
            (withExpandableRows || withItems) ?
              <TableCell style={{width: '15px'}}></TableCell> :
              ''
          }

          <TableCell>
            <Typography
              sx={{ flex: '1 1 100%', fontFamily: 'Poppins, sans-serif' }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} {numSelected > 1 ? 'selectate' : 'selectat'}
            </Typography>
          </TableCell>

          {values.length >= 1 ? new Array(values.length - 1).fill(0).map((_, index) => (
            <TableCell key={index}></TableCell>
          )) : ''
          }

          {
            actionItems === undefined ? "" : <TableCell style={{width: '40px'}}></TableCell>
          }

        </TableRow>

      ) : (
        <TableRow
          sx={{
            backgroundColor: themeVariables.colors.lightGray,
          }}
        >
          {
            !withoutSelect ?
              <TableCell
                style={{ width: "20px" }}
                align="center"
                padding="checkbox"
              >
                <Checkbox
                  color="primary"
                  checked={selectedState.isAllSelected || selectedState.selectedItems.length === totalNumberOfElements}
                  onChange={onSelectAllClick}
                />
              </TableCell> :
              ''
          }

          {
            (withExpandableRows || withItems) ?
              <TableCell style={{width: '15px'}}></TableCell> :
              ''
          }

          {values.map(renderCell)}

          {
            actionItems === undefined ? "" : <TableCell sx={{
              borderTopRightRadius: "0.5rem",
              borderBottomRightRadius: "0.5rem",
            }}></TableCell>
          }
        </TableRow>
      )}

      {
        isLoading ?
          <TableRow>
            <TableCell style={{padding: 0}} colSpan={numberOfCells}>
              <LinearProgress style={{borderRadius: '10px'}} />
            </TableCell>
          </TableRow> :
          ''
      }
    </TableHead>
  );
}

export type ActionItemPropsType = {
  text: string,
  icon: any,
  color: string,
  canDisplay?: (row: any) => boolean;
  fOnClick: (row: any, tableActions: TableActionsPropsType, index?: number) => void;
}

export type TableDataPropsType = {
  data: any[];
  headers: HeadersPropsType[];
  totalElements?: number;
  totalUnfilteredElements?: number;
}

export type TabOptionsPropsType = {
  label: string,
  value: any,
}

export type DropdownOptionsPropsType = {
  label: string,
  value: any,
}

export type TableActionsPropsType = {
  updateRow: (rowId: string, row: any) => void,
  setLoading: (loadingState: boolean) => void
}

export type CustomRendererPropsType = {
  [key: string]: (row: any, tableActions: TableActionsPropsType) => React.ReactNode
}

export type FooterPropsType = {
  numberOfCells: number;
  withActionItems?: boolean;
}

type TableComponentPropsType = {
  url?: string,
  searchBarPlaceholder?: string,
  dropdownPlaceholder?: string,
  secondDropdownPlaceholder?: string,
  dropdownOptions?: DropdownOptionsPropsType[],
  secondDropdownOptions?: DropdownOptionsPropsType[],
  tabs?: TabOptionsPropsType[],
  actionItems?: ActionItemPropsType[]
  tableKey: TableTypeEnum;
  customRenderer?: CustomRendererPropsType;
  onUpdateConfirm?: (updatedRows: any[]) => void
  withoutDenseSwitch?: boolean;
  tableData?: TableDataPropsType;
  tableDataChild?: TableDataPropsType;
  onSelectRows?: (selectedState: SelectedStatePropsType) => void,
  withoutSelect?: boolean;
  withoutPagination?: boolean;
  withoutSearchBar?: boolean;
  viewType?: 'normal' | 'panel' | 'read-only' | 'minimal';
  preselectedItems?: any[];
  alignData?: 'top' | 'center';
  expandableRenderer?: (row: any, tableActions: TableActionsPropsType) => React.ReactNode;
  denseByDefault?: boolean;
  footerRenderer?: (props: FooterPropsType) => React.ReactNode;
  withoutPersist?: boolean;
  canDisplayCustomRenderer?: (row: any) => boolean;
}

type HeadersPropsType = {
  id: string,
  label: string,
  alignment: 'left' | 'center' | 'right' | 'justify' | 'inherit',
  headerType: string,
  sortable: boolean,
  width?: string
}

type DataPropsType = {
  headers: HeadersPropsType[];
  data: any[];
  totalElements: number;
  totalUnfilteredElements: number;
}

type TableStatePropsType = {
  pageIndex: number;
  pageSize: number;
  searchText?: string;
  selectFilterValue?: any;
  secondSelectFilterValue?: any;
  tabFilterValue?: any;
  url?: string;
}

export type SelectedStatePropsType = {
  selectedItems: any[];
  isAllSelected: boolean;
}

const debounceFactory = () => {
  let timeoutId: any = 0;
  return (
    tableState: TableStatePropsType,
    setData: React.Dispatch<React.SetStateAction<DataPropsType>>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setIsInit: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    if (timeoutId > 0) {
      clearTimeout(timeoutId);
      timeoutId = 0;
    }

    if (!tableState.url) return;

    timeoutId = setTimeout(() => {
      setIsLoading(() => true);
      getRequest({
        url: tableState.url ?? '',
        queryParams: {
          pageIndex: tableState.pageIndex + 1,
          pageSize: tableState.pageSize,
          searchText: isEmpty(tableState.searchText) ? undefined : tableState.searchText,
          selectFilterValue: tableState.selectFilterValue === -1 ? undefined : tableState.selectFilterValue,
          secondSelectFilterValue: tableState.secondSelectFilterValue === -1 ? undefined : tableState.secondSelectFilterValue,
          tabFilterValue: tableState.tabFilterValue,
        }
      })
        .then(data => {
          setData(() => ({
            data: data.data.results.data,
            headers: data.data.results.headers,
            totalElements: data.data.totalElements,
            totalUnfilteredElements: data.data.totalUnfilteredElements
          }));
          setIsLoading(() => false);
          setIsInit(() => true);
        })
    }, 100);
  }
}

const TableComponent = observer(({
  url,
  searchBarPlaceholder,
  dropdownPlaceholder,
  secondDropdownPlaceholder,
  dropdownOptions,
  tabs,
  actionItems,
  tableKey,
  customRenderer = {},
  withoutPersist = false,
  onUpdateConfirm = () => { },
  withoutDenseSwitch = false,
  onSelectRows = () => { },
  tableData,
  withoutSelect,
  withoutPagination,
  withoutSearchBar,
  viewType = 'normal',
  preselectedItems,
  alignData = 'center',
  expandableRenderer,
  denseByDefault = false,
  footerRenderer,
  secondDropdownOptions,
  canDisplayCustomRenderer: canDisplayCustomerRenderer,
  tableDataChild,
}: TableComponentPropsType) => {

  /** inject mobx store inside  */
  const tableStore = useStore('tableStore');
  const uiStore = useStore('uiStore');

  /** Data fetched from the API */
  const [data, setData] = React.useState<DataPropsType>({
    data: [],
    headers: [],
    totalElements: 0,
    totalUnfilteredElements: 0
  });


  /** Variables that will be sent to the API */
  const [tableState, setTableState] = React.useState<TableStatePropsType>((function (): TableStatePropsType {
    const cachedState = localStorage.getItem(tableKey);
    if (!cachedState || withoutPersist) return {
      pageIndex: 0,
      pageSize: 5,
      selectFilterValue: -1,
      secondSelectFilterValue: -1,
      tabFilterValue: undefined,
      searchText: undefined,
      url: url
    }

    return {
      ...JSON.parse(cachedState),
      url: url
    }
  })());

  /** State object that deals with selected items */
  const [selectedState, setSelectedState] = React.useState<SelectedStatePropsType>({
    selectedItems: [],
    isAllSelected: false
  });

  /** Other state variables */
  const [tabIndex, setTabIndex] = React.useState(0);
  const [dense, setDense] = React.useState(denseByDefault);
  const [updatedData, setUpdatedData] = React.useState<any[]>([]);
  const [isExpanded, setIsExpanded] = React.useState<{ [rowId: string]: boolean }>({});
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isInit, setIsInit] = React.useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refreshData = React.useCallback(
    debounceFactory(),
    []
  )

  React.useEffect(() => {
    setTableState(tableState => ({
      ...tableState,
      pageIndex: 0,
      pageSize: 5,
      selectFilterValue: -1,
      secondSelectFilterValue: -1,
      tabFilterValue: undefined,
      searchText: undefined,
    }));
  }, []);

  React.useEffect(() =>{
    if(!tableData) return
    if(tableState.searchText){
      let tableDataValues = [...tableData.data]
      let searchTextLowerCase = tableState.searchText.toLocaleLowerCase()
      // eslint-disable-next-line array-callback-return
      let tableValues = tableDataValues.filter(x => {
        if(x.sku && (x.sku as string).toLocaleLowerCase().includes(searchTextLowerCase))
          return x
        else if(x.name && (x.name as string).toLocaleLowerCase().includes(searchTextLowerCase))
          return x
      }).filter(x => x)
  
      setData(() => ({
        data: tableValues,
        headers: tableData.headers,
        totalElements: tableValues.length,
        totalUnfilteredElements: tableValues.length
      }));

    } else {
      setData(() => ({
        data: tableData.data,
        headers: tableData.headers,
        totalElements: tableData?.totalElements ?? tableData.data.length,
        totalUnfilteredElements: tableData?.totalUnfilteredElements ?? tableData.data.length
      }));
  
      setIsInit(() => true);
    }

  },[tableData, tableState.searchText])

  React.useEffect(() => {
    if (!tableDataChild) return;
    setData(() => ({
      data: tableDataChild.data,
      headers: tableDataChild.headers,
      totalElements: tableDataChild?.totalElements ?? tableDataChild.data.length,
      totalUnfilteredElements: tableDataChild?.totalUnfilteredElements ?? tableDataChild.data.length
    }));

    setIsInit(() => true);
  }, [tableDataChild])

  React.useEffect( 
    () => {
       tableStore.setUpdatedData(updatedData)
     },
     [updatedData, tableStore]
   )
 
  React.useEffect(() => {
    if (!preselectedItems) return;

    setSelectedState(() => ({
      selectedItems: preselectedItems,
      isAllSelected: false,
    }));
  }, [preselectedItems]);

  React.useEffect(() => {
    if (url !== tableState.url)
      setTableState(tableState => ({
        ...tableState,
        url: url
      }));
  }, [url, tableState]);

  React.useEffect(() => {
    if (isEmpty(updatedData)) return;

    uiStore.showPersistentToast({
      message: `${updatedData.length} ${updatedData.length > 1 ? 'produse modificate' : 'produs modificat'}`,
      action: response => {
        if (response === 'dismiss') {
          tableStore.resetOldUpdatedData()
          setUpdatedData(() => []);
          return;
        };
        onUpdateConfirm(updatedData);
      }
    });
  }, [updatedData, tableStore, onUpdateConfirm, uiStore]);

  React.useEffect(() => {
    onSelectRows(selectedState);
  }, [onSelectRows, selectedState]);

  React.useEffect(() => {
    return () => {
      tableStore.resetTable(tableKey)
    }
  }, [tableStore, tableKey]);

  const parsedData: any[] = React.useMemo(
    (): any[] => {
      return data.data.map(row => {
        let updatedRow = updatedData.find(f => f.id === row.id);
        if (updatedRow) return updatedRow;
        return row;
      });
    },
    [data.data, updatedData]
  )

  // Adaugam tab-ul All
  const innerTabs: TabOptionsPropsType[] = React.useMemo(
    (): TabOptionsPropsType[] => {

      const allLabel = {
        label: "Toate",
        value: undefined,
      }

      if (tabs)
        return [allLabel, ...tabs]
      else
        return []
    },
    [tabs]
  );

  /** We construct the inner dropdown options array */
  const innerDropdownOptions: DropdownOptionsPropsType[] = React.useMemo(
    (): DropdownOptionsPropsType[] => {

      const allOption = {
        label: "Toate",
        value: -1,
      }

      if (dropdownOptions)
        return [allOption, ...dropdownOptions]
      else
        return []

    },
    [dropdownOptions]
  )

  const secondInnerDropdownOptions: DropdownOptionsPropsType[] = React.useMemo(
    (): DropdownOptionsPropsType[] => {

      const allOption = {
        label: "Toate",
        value: -1,
      }

      if (secondDropdownOptions)
        return [allOption, ...secondDropdownOptions]
      else
        return []

    },
    [secondDropdownOptions]
  )

  //Change tab's value
  const changeTab = React.useCallback(
    (_, newValue: number) => {
      if (isEmpty(tabs))
        return;

      if (tableState.tabFilterValue !== innerTabs[newValue].value)
        setTableState(tableState => ({
          ...tableState,
          tabFilterValue: innerTabs[newValue].value,
          pageIndex: 0
        }));

      setTabIndex(newValue)
    },
    [tabs, innerTabs, tableState]
  )

  React.useEffect(() => {
    if (!tableStore.dependencyTable[tableKey]) return;
    setUpdatedData(() => []);
    refreshData(tableState, setData, setIsLoading, setIsInit);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData, tableKey, tableState, tableStore.dependencyTable[tableKey]]);

  React.useEffect(() => {
    refreshData(tableState, setData, setIsLoading, setIsInit);
  }, [refreshData, tableState]);

  React.useEffect(() => {
    if (!withoutPersist)
      localStorage.setItem(tableKey, JSON.stringify(tableState));
  }, [tableState, tableKey, withoutPersist])


  const handleSelectAllClick = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.checked) {
        if (selectedState.isAllSelected && isEmpty(selectedState.selectedItems))
          setSelectedState(() => ({
            isAllSelected: false,
            selectedItems: []
          }));
        else if (!selectedState.isAllSelected && selectedState.selectedItems.length === data.totalUnfilteredElements) {
          setSelectedState(() => ({
            isAllSelected: false,
            selectedItems: []
          }));
        } else {
          setSelectedState(() => ({
            isAllSelected: true,
            selectedItems: []
          }));
        }
      } else {
        setSelectedState(() => ({
          isAllSelected: true,
          selectedItems: []
        }));
      }

    },
    [selectedState, data.totalUnfilteredElements]
  )

  const handleClick = React.useCallback(
    (event: React.MouseEvent<unknown>, row: any) => {
      const selectedIndex = selectedState.selectedItems.findIndex((i: any) => i.id === row.id);
      let newSelected: string[] = [];
      if (selectedIndex === -1) {
        newSelected = [...selectedState.selectedItems, row];
      } else {
        newSelected = selectedState.selectedItems.filter(f => f.id !== row.id);
      }

      setSelectedState(() => ({
        ...selectedState,
        selectedItems: newSelected
      }));
    },
    [selectedState]
  )

  const handleChangePage = React.useCallback(
    (_: unknown, newPageIndex: number) => {
      if (newPageIndex !== tableState.pageIndex)
        setTableState(tableState => ({
          ...tableState,
          pageIndex: newPageIndex
        }))
    },
    [tableState]
  );

  const handleChangeRowsPerPage = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newPageSize = parseInt(event.target.value, 10);
      if (newPageSize !== tableState.pageSize)
        setTableState(tableState => ({
          ...tableState,
          pageIndex: 0,
          pageSize: newPageSize
        }));
    },
    [tableState]
  );

  const handleChangeDense = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDense(() => event.target.checked);
    },
    []
  );

  const isSelected = React.useCallback(
    (row: any): boolean => {
      return (selectedState.isAllSelected && selectedState.selectedItems.findIndex((i: any) => i.id === row.id) === -1) || (!selectedState.isAllSelected && selectedState.selectedItems.findIndex((i: any) => i.id === row.id) !== -1);
    },
    [selectedState]
  );

  const randerCellBody = React.useCallback(
    (header: HeadersPropsType, row: any) => {
      switch (header.headerType) {
        case HeaderTypeEnum.Array:
          return row[header.id].map((i: any) => {
            return (
              <div key={i}>
                <Chip size="small" label={i} />
              </div>
            )
          })

        case HeaderTypeEnum.Boolean:
          return <Chip size="small" color={row[header.id].value ? "success" : "error"} label={row[header.id].label} />

        case HeaderTypeEnum.String:
        case HeaderTypeEnum.Numeric:
          return row[header.id]

        default:
          break;
      }
    },
    []
  )

  const onSelectValueChange = React.useCallback(
    (newValue: any) => {
      if (tableState.selectFilterValue !== newValue)
        setTableState(tableState => ({
          ...tableState,
          selectFilterValue: newValue,
          pageIndex: 0
        }))
    },
    [tableState]
  )

  const onSecondSelectValueChange = React.useCallback(
    (newValue: any) => {
      if (tableState.secondSelectFilterValue !== newValue)
        setTableState(tableState => ({
          ...tableState,
          secondSelectFilterValue: newValue,
          pageIndex: 0
        }))
    },
    [tableState]
  )

  const onSearchBarValueChange = React.useCallback(
    (newValue: string) => {
      if (tableState.searchText !== newValue)
        setTableState(tableState => ({
          ...tableState,
          searchText: newValue,
          pageIndex: 0,
        }))
    },
    [tableState]
  )

  const updateRow = React.useCallback(
    (rowId: string, updatedRow: any) => {
      setUpdatedData(data => {
        return [...data.filter(f => f.id !== rowId), updatedRow]
      });
    },
    []
  )

  const setLoadingState = React.useCallback(
    (loadingState: boolean) => {
      setIsLoading(() => loadingState);
    },
    []
  )

  const tableActions = React.useMemo(
    (): TableActionsPropsType => {
      return {
        updateRow: updateRow,
        setLoading: setLoadingState
      }
    },
    [updateRow, setLoadingState]
  )

  const renderNoDataImage = React.useCallback(
    () => {
      return (
        <TableRow>
          <TableCell colSpan={actionItems === undefined ? data.headers.length + 1 : data.headers.length + 2}>
            <NoDataComponent />
          </TableCell>
        </TableRow>
      )
    }, [actionItems, data.headers.length])

  const getNumberOfCells = React.useCallback(
    (): number => {
      let numberOfCells = data.headers.length;
      
      if (!withoutSelect) numberOfCells += 1;
      if (actionItems) numberOfCells += 1;
      if (expandableRenderer) numberOfCells += 1;
      if (parsedData.some(s => !isEmpty(s.items))) numberOfCells += 1;
      
      return numberOfCells;
    },
    [parsedData, data.headers, withoutSelect, actionItems, expandableRenderer]
  )

  const renderTableRow = (row: any, index: number, items: any[], depth: number = 1) => {
    const isItemSelected = isSelected(row);
    const isItemUpdated = updatedData.findIndex(f => f.id === row.id) >= 0;
    const labelId = `enhanced-table-checkbox-${index}`;

    return (
      <React.Fragment key={row.id}>
        <TableRow
          key={row.id}
          className='MuiTableRow-root'
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          style={{borderBottom: (depth > 1 && index < items.length-1) ? 'none' : '' }}
          selected={isItemSelected || isItemUpdated}
        >
          {
            depth > 1 ?
              <>
                { new Array(depth-1).fill(0).map((_: any, index: number) => (
                  <TableCell key={index}></TableCell>
                )) }
              </> :
              ''
          }

          {
            !withoutSelect ?
              <TableCell padding="checkbox" align='center' style={{ width: '20px' }}>
                {
                  isEmpty(row.items) ?
                    <Checkbox
                      className='MuiButtonBase-root-MuiCheckbox-root'
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                      onClick={(event) => handleClick(event, row)}
                    /> :
                    ''
                }
              </TableCell> :
              ''
          }

          {
            expandableRenderer ?
              <TableCell style={{ paddingRight: '0', marginRight: '-1rem', width: '15px' }}>
                {
                  (!canDisplayCustomerRenderer || canDisplayCustomerRenderer(row)) ?
                    <IconButton
                      aria-label="expand row"
                      style={{ padding: '0' }}
                      size="small"
                      onClick={() => onExpandButtonClick(row.id)}
                    >
                      {!!isExpanded[row.id] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton> :
                    ''
                }  
              </TableCell> :
              ''
          }

          {
            (depth === 1 && parsedData.some(s => !isEmpty(s.items))) ?
              <TableCell style={{paddingRight: '0', marginRight: '-1rem', width: '15px'}}>
                {
                  !isEmpty(row.items) ?
                    <IconButton
                      aria-label="expand row"
                      style={{ padding: '0' }}
                      size="small"
                      onClick={() => onExpandButtonClick(row.id)}
                    >
                      {!!isExpanded[row.id] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton> :
                    ''
                }
              </TableCell> :
              ''
          }

          {data.headers.map(
            (header) => (
              <TableCell
                key={header.id}
                component="th"
                id={labelId}
                scope="row"
                align={header.alignment}
                sx={{
                  '&': {
                    verticalAlign: alignData === 'top' ? 'baseline' : 'inherit'
                  }
                }}
                style={{ width: header.width ?? 'unset' }}
              >
                {
                  customRenderer[header.id] ?
                    customRenderer[header.id](row, tableActions) :
                    randerCellBody(header, row)
                }
              </TableCell>
            ))
          }

          {
            actionItems === undefined ? ""
              :
              <TableCell align="right" style={{width: '40px'}}>
                <TableActionMenu actionItems={actionItems} row={row} tableActions={tableActions} />
              </TableCell>
          }

        </TableRow>

        {
          expandableRenderer ?
            <TableRow style={{ borderBottom: 'none' }}>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}></TableCell>
              <TableCell style={{ padding: 0 }} colSpan={getNumberOfCells() - 1}>
                <Collapse in={!!isExpanded[row.id]} timeout="auto" unmountOnExit>
                  <Box paddingY={2}>
                    {expandableRenderer(row, tableActions)}
                    {
                      viewType !== 'read-only' ?
                        <Divider style={{ marginTop: '1rem' }} /> :
                        ''
                    }
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow> :
            ''
        }

        {
          (!isEmpty(row.items) && !!isExpanded[row.id]) ?
            row.items.map((item: any, index: number, items: any[]) => renderTableRow(item, index, items, depth+1)) :
            ''
        }

      </React.Fragment>
    );
  };

  const getNumberOfSelectedItems = React.useCallback(
    (): number => {
      if (selectedState.isAllSelected)
        return data.totalUnfilteredElements - selectedState.selectedItems.length;
      else
        return selectedState.selectedItems.length;
    },
    [selectedState, data]
  )


  const onExpandButtonClick = React.useCallback(
    (rowId: string) => {
      const expandedCopy = cloneDeep(isExpanded);
      expandedCopy[rowId] = !expandedCopy[rowId];
      setIsExpanded(() => expandedCopy);
    },
    [isExpanded]
  )

  return (
    <TableComponentStyled viewType={viewType}>
      <Box sx={{ width: '100%' }}>
        <Paper
          className='MuiPaper-root'
          sx={{ width: '100%', mb: 2, }}
        >

          {/* Filters */}
          {tabs ? <CenteredTabs handleChange={changeTab} tabValue={tabIndex} tabOptions={innerTabs} /> : ""}

          {
            (secondDropdownOptions || dropdownOptions || !withoutSearchBar) ?
              <DropdownSearchBarStyled>
                {
                  dropdownOptions ?
                    <BasicSelect
                      placeholder={dropdownPlaceholder ?? 'Cauta'}
                      options={innerDropdownOptions}
                      selectFilterValue={tableState.selectFilterValue}
                      onValueChange={onSelectValueChange}
                    /> :
                    ''
                }
                {
                  secondDropdownOptions ?
                    <BasicSelect
                      placeholder={secondDropdownPlaceholder ?? 'Cauta'}
                      options={secondInnerDropdownOptions}
                      selectFilterValue={tableState.secondSelectFilterValue}
                      onValueChange={onSecondSelectValueChange}
                    /> :
                    ''
                }
                {
                  !withoutSearchBar ?
                    <SearchAppBar
                      placeholder={searchBarPlaceholder ?? 'Cauta'}
                      searchText={tableState.searchText ?? ''}
                      onValueChange={onSearchBarValueChange}
                    /> : ''
                }
              </DropdownSearchBarStyled> :
              ''
          }
          
          {/* Table */}
          <TableContainer sx={{ boxSizing: 'border-box' }}>
            <Table
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              {
                viewType !== 'minimal' ?
                  <EnhancedTableHead
                    numSelected={getNumberOfSelectedItems()}
                    onSelectAllClick={handleSelectAllClick}
                    selectedState={selectedState}
                    totalNumberOfElements={data.totalUnfilteredElements}
                    values={data.headers}
                    actionItems={actionItems}
                    withoutSelect={withoutSelect}
                    isLoading={isLoading}
                    numberOfCells={getNumberOfCells()}
                    withExpandableRows={!!expandableRenderer}
                    withItems={parsedData.some(s => !isEmpty(s.items))}
                  /> :
                  ''
              }
              
              <TableBody>
                {(data.totalElements === 0 && isInit) ? renderNoDataImage() : ""}

                {
                  tableData && !withoutPagination ?
                      parsedData
                        .slice(tableState.pageIndex * tableState.pageSize, tableState.pageIndex * tableState.pageSize + tableState.pageSize)
                        .map((row: any, index: number, items: any[]) => renderTableRow(row, index, items, 1))
                      :
                      parsedData
                        .map((row: any, index: number, items: any[]) => renderTableRow(row, index, items, 1))
                }
                
              </TableBody>

              {
                (footerRenderer && parsedData.length > 0) ?
                  <TableFooter>
                    {footerRenderer({ numberOfCells: getNumberOfCells(), withActionItems: !!actionItems })}
                  </TableFooter> :
                  ''
              }

            </Table>
          </TableContainer>

          {/* Footer */}
          {
            (!withoutDenseSwitch || !withoutPagination) ?
              <FooterTableStyled isDense={!withoutDenseSwitch} isPagaination={!withoutPagination}>
                {
                  !withoutDenseSwitch ?
                    <FormControlLabel
                      sx={{
                        fontFamily: 'Poppins, sans-serif',
                        paddingLeft: '1rem'
                      }}
                      control={<Switch checked={dense} onChange={handleChangeDense} color="success" />}
                      label={dense ? "Compact" : "Confortabil"}
                    /> :
                    ''
                }

                {
                  !withoutPagination ?
                    <TablePagination
                      sx={{
                        fontFamily: 'Poppins, sans-serif !important',
                      }}
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={data.totalElements}
                      rowsPerPage={tableState.pageSize}
                      page={tableState.pageIndex}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    /> :
                    ''
                }

              </FooterTableStyled> :
              ''
          }

        </Paper>
      </Box>
    </TableComponentStyled>
  );
})

export default TableComponent;