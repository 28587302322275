import { Grid } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { PaymentTypeEnum } from "../../enums/payment-type.enum";
import { TableTypeEnum } from "../../enums/table-type.enum";
import { useStore } from "../../hooks/store.hook";
import { StateFieldType } from "../../types/form.types";
import { OrderType } from "../../types/order.type";
import { updatePayment } from "../../utils/requests";
import ButtonComponent from "../button/button.component";
import BasicSelect from "../dropdown-table-component/dropdown-table-component.component";
import { DropdownOptionsPropsType } from "../table/table.component";
import TextFieldComponent from "../text-field/text-field.component";
import EditPaymentComponentStyled from "./edit-payment.component.styled";


type StateType = {
    fields: {
        type: StateFieldType<PaymentTypeEnum>;
        notes: StateFieldType<string>;
    };
    shouldDisplayError: boolean;
}

export type EditPaymentComponentPropsType = {
    orderDetails?: OrderType
}

const EditPaymentComponent = ({
    orderDetails
}: EditPaymentComponentPropsType) => {

    const uiStore = useStore('uiStore');
    const tableStore = useStore('tableStore');

    const [state, setState] = useState<StateType>({
        fields: {
            type: {
                value: PaymentTypeEnum.Unpaid,
                isValid: false,
                errorMessage: 'Furnizati un tip de plata valid'
            },
            notes: {
                value: '',
                noValidation: true,
                isValid: true,
            }
        },
        shouldDisplayError: false
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const updateStateField = useCallback(
        (currentField: StateFieldType<any>, newValue: any): StateFieldType<any> => {
            return {
                ...currentField,
                isValid: (currentField.validator ? (currentField.validator as any)(newValue) : !!newValue) || 
                    currentField.noValidation,
                value: newValue
            }
        },
        []
    )

    const updateState = useCallback(
        <T extends keyof typeof state.fields>(field: T, newValue: any) => {
            setState(state => ({
                ...state,
                fields: {
                    ...state.fields,
                    [field]: updateStateField(state.fields[field], newValue)
                }
            }))
        },
        [state, updateStateField]
    )

    useEffect(
        () => {
            setState(state => ({
                ...state,
                fields: {
                    ...state.fields,
                    type: updateStateField(state.fields.type, orderDetails?.payment?.type ?? PaymentTypeEnum.Unpaid),
                    notes: updateStateField(state.fields.notes, orderDetails?.payment?.notes ?? '')
                }
            }))
        },
        [orderDetails, updateStateField]
    )

    const updatePaymentStatus = useCallback(
        () => {
            setState(state => ({
                ...state,
                shouldDisplayError: true
            }));

            if (!state.fields.type.isValid) return;

            setIsLoading(() => true);
            updatePayment(orderDetails?.id ?? '', {
                payment: {
                    isPaid: !!(state.fields.type.value !== PaymentTypeEnum.Unpaid),
                    type: state.fields.type.value,
                    notes: state.fields.notes.value
                }
            })
                .then(() => {
                    toast.success('Statusul platii a fost modificat cu succes')
                    tableStore.updateTable(TableTypeEnum.Orders);
                    uiStore.dismissPanel({
                        data: {
                            ...orderDetails,
                            payment: {
                                isPaid: !!(state.fields.type.value !== PaymentTypeEnum.Unpaid),
                                type: state.fields.type.value,
                                notes: state.fields.notes.value
                        }
                    }});
                })
                .catch((e: any) => {
                    toast.error(e.message);
                })
                .finally(() => {
                    setIsLoading(() => false);
                })

        },
        [state, orderDetails, tableStore, uiStore]
    )

    const innerDropdownOptions: DropdownOptionsPropsType[] = React.useMemo(
        (): DropdownOptionsPropsType[] => {
          const dropdownOptions = Object.values(PaymentTypeEnum).map(role => ({ label: role, value: role }))
    
          if(dropdownOptions)
            return dropdownOptions
          else
            return []
    
        },
        []
      )

    const onSelectValueChange = React.useCallback(
        (newValue: any) => {
          if (state.fields.type.value !== newValue)
            setState(state => ({
                ...state,
                fields: {
                    ...state.fields,
                    type: updateStateField(state.fields.type, newValue)

                }
            }));
        },
        [state, updateStateField]
      )

    const customStyle = useMemo (
        () => ({
            marginRight: '0',
            marginBottom: '0.625rem'
        }),
        []
    )

    /** define the return statement bellow */
    return (
        <EditPaymentComponentStyled>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <BasicSelect
                      placeholder={'Tip plata'}
                      options={innerDropdownOptions}
                      selectFilterValue={state.fields.type.value}
                      onValueChange={onSelectValueChange}
                      customStyle={customStyle}
                    />

                    <TextFieldComponent
                        label="Notite" 
                        variant="outlined" 
                        fullWidth={true}
                        multiline
                        rows={2}
                        value={state.fields.notes.value}
                        error={state.shouldDisplayError && !state.fields.notes.isValid}
                        helperText={state.shouldDisplayError && !state.fields.notes.isValid && state.fields.notes.errorMessage}
                        onTextChange={e => updateState('notes', e)}
                    />
                </Grid>
            </Grid>

            <div className="button-container">
                <ButtonComponent onClick={updatePaymentStatus} isLoading={isLoading}>
                    Salveaza
                </ButtonComponent>
            </div>
        </EditPaymentComponentStyled>
    )

}

export default EditPaymentComponent;