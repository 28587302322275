import { Paper } from '@mui/material'
import { formatNumber } from '../../utils/utils';
import { CurrencyEnum } from '../../enums/currency.enum';
import { TextOneStyled, TextTwoStyled } from './card-numeric-action-log.component.styled';

export type CardNumericRaportPropsType = {
    details:{
        value: any,
        price: boolean
    },
    label: string,
}

const CardNumericActionLogRaport = ({
    label,
    details,
}: CardNumericRaportPropsType) => {
    return (
        <>
            {  
                <Paper 
                elevation={2} 
                sx={{
                        display: 'flex',
                        width: '7rem',
                        height: '4.5rem',
                        padding: '0.3rem', 
                        paddingTop: '1rem',
                        borderRadius: '1rem',
                        margin: '0.5rem',
                        boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
                        justifyContent: 'center'
                    }}
                >
                    <div style={{display: 'flex',  alignItems: 'center', flexDirection: 'column', justifyContent: 'center'}}>
                        <TextOneStyled>{label}</TextOneStyled>
                        <TextTwoStyled>
                            {
                                details.price ?
                                    `${formatNumber(details.value)} ${CurrencyEnum.RON}`
                                    :
                                    details.value
                            }
                        </TextTwoStyled>
                    </div>      
                </Paper>
            }
        </>
                    
    )
}

export default CardNumericActionLogRaport;