import { Paper } from "@mui/material";
import { useMemo } from "react";
import Chart from "react-apexcharts";
import { CenterChart } from "../pie-chart/pie-chart.component.styled";
import { DonutPatternChartStyled, TitleDonutPatternChartStyled } from "./donut-pattern-chart.component.styled";

export type DonutPatternChartPropsType = {
    details: {
        label: string[],
        value: any[]
    },
    generic: {
        label: string,
    }
}

const DonutPatternChart = ({
    details,
    generic
}: DonutPatternChartPropsType) => {

    const date: any = useMemo(
        () => {
            return {
                series: details.value,
                options: {
                    chart: {
                        width: 25
                    },
                    legend: {
                        position: "bottom",
                        fontSize: '15px',
                    },
                    tooltip: {
                        fillSeriesColor: false,
                        theme: 'dark',
                        style: {
                            fontSize: "20px",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: 400
                        }
                    },
                    stroke: {
                        width: 2,
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    total: {
                                        showAlways: true,
                                        show: true,
                                        fontSize: "20px",
                                        fontFamily: "Helvetica, Arial, sans-serif",
                                        fontWeight: 400,
                                    },
                                    value: {
                                        show: true,
                                        fontSize: "25px",
                                        fontFamily: "Helvetica, Arial, sans-serif",
                                        fontWeight: 400,
                                    }
                                },
                                size: '92px',
                            },
                            expandOnClick: false,
                        }
                    },
                    labels: details.label,
                    states: {
                        hover: {
                            filter: 'none'
                        }
                    },
                    dataLabels: {
                        formatter: function (_val: any, opts: any) {
                            return ''
                        },
                        style: {
                            fontSize: "15px",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: 400,
                        },
                        background: {
                            borderWidth: 10
                        }
                    },
                },
            }
        },
        [details]
    )

    return (
        <Paper sx={{ 
            margin: '0.5rem',
            boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
            borderRadius: '1rem',
            width: '23rem'
        }}>
            <DonutPatternChartStyled>
                <TitleDonutPatternChartStyled> {generic.label} </TitleDonutPatternChartStyled>
                <CenterChart>
                    <Chart
                        options={date.options}
                        series={date.series}
                        type="donut"
                        width='400'
                    />
                </CenterChart>
            </DonutPatternChartStyled>
        </Paper>
    )
}

export default DonutPatternChart;