import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, {useCallback, useState} from "react";
import ForgotPasswordPageStyled from "./forgot-password.page.styled";
import {useNavigate} from "react-router-dom";
import FormElemWrapperComponent from "../../../../components/form-elem-wrapper/form-elem-wrapper.component";
import TextFieldComponent from "../../../../components/text-field/text-field.component";
import ButtonComponent from "../../../../components/button/button.component";
import { postRequest } from "../../../../utils/http-requests";
import { StateFieldType } from "../../../../types/form.types";
import SuccessMarkContainerComponent from "../../../../components/success-mark-container/success-mark-container.component";
import toast from "react-hot-toast";

enum FlowPhase {
    EnterEmailAdress,
    EnterNewPassword
}

type StateType = {
    fields: {
        email: StateFieldType<string>;
        code: StateFieldType<string>;
        newPassword: StateFieldType<string>;
    };
    shouldDisplayError: boolean;
}

const buttonLabelByPhase = Object.freeze({
   [FlowPhase.EnterEmailAdress]: 'Trimite e-mail-ul',
   [FlowPhase.EnterNewPassword]: 'Reseteaza parola'
});

const ForgotPasswordPage = () => {

    /** inject navigator to navigate to other routes */
    const navigate = useNavigate();

    const [state, setState] = useState<StateType>({
        fields: {
            email: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati o adresa de e-mail valida'
            },
            code: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati codul trimis prin e-mail'
            },
            newPassword: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati o noua parola'
            }
        },
        shouldDisplayError: false
    });
    const [phase, setPhase] = useState<FlowPhase>(FlowPhase.EnterEmailAdress);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);


    const onGoBackClick = useCallback(
        () => {
            navigate(-1);
        },
        [navigate]
    )

    const updateState = useCallback(
        (field: 'email' | 'code' | 'newPassword', newValue: string) => {
            setState(() => ({
                ...state,
                fields: {
                    ...state.fields,
                    [field]: {
                        ...state.fields[field],
                        isValid: state.fields[field].validator ? (state.fields[field].validator as any)(newValue) : !!newValue,
                        value: newValue
                    }
                }
            }));
        },
        [state]
    )

    const onButtonClick = useCallback(
        async () => {
            setState(() => ({
                ...state,
                shouldDisplayError: true
            }));

            try {
                setIsLoading(() => true);
                
                if (phase === FlowPhase.EnterEmailAdress) {
                    if (!state.fields.email.isValid) return;
    
                    await postRequest({ url: '/auth/forgot-password', body: { email: state.fields.email.value } });

                    setState(() => ({
                        ...state,
                        shouldDisplayError: false
                    }));
                    setPhase(() => FlowPhase.EnterNewPassword);
                } else {
                    if (!state.fields.email.isValid || !state.fields.code.isValid || !state.fields.newPassword.isValid) return;

                    await postRequest({ url: '/auth/reset-password', body: { 
                            email: state.fields.email.value,
                            code: state.fields.code.value,
                            newPassword: state.fields.newPassword.value,
                        } 
                    });

                    setIsSuccess(() => true);
                }
            } catch(error: any) {
                toast.error(error.message);
            } finally {
                setIsLoading(() => false);
            }

            
        },
        [phase, state]
    );

    const onAnimationEnd = useCallback(
        () => {
            navigate(-1); 
        },
        [navigate]
    );


    /** define the return statement bellow */
    return (
        <ForgotPasswordPageStyled>
            {
                isSuccess ?
                <SuccessMarkContainerComponent
                    message="Parola a fost resetata cu succes"
                    onAnimationEnd={onAnimationEnd}
                /> :
                <>
                    <div className="page-header">
                        <div className="back-button">
                            <IconButton aria-label="go-back" onClick={onGoBackClick}>
                                <ArrowBack />
                            </IconButton>
                            <span>Inapoi la autentificare</span>
                        </div>    
                        <div className="title">
                            <span>Ai uitat parola?</span>
                        </div>
                        <div className="subtitle">Introdu adresa de e-mail iar noi iti vom trimite un cod pentru resetarea parolei</div>
                    </div>

                    <div className="input-elements">
                        <FormElemWrapperComponent>
                            <TextFieldComponent 
                                label="Adresa de email" 
                                variant="outlined" 
                                fullWidth={true}
                                value={state.fields.email.value}
                                error={state.shouldDisplayError && !state.fields.email.isValid}
                                helperText={state.shouldDisplayError && !state.fields.email.isValid && state.fields.email.errorMessage}
                                onTextChange={e => updateState('email', e)}
                            />
                        </FormElemWrapperComponent>
                        {
                            phase === FlowPhase.EnterNewPassword ?
                            <>
            
                                <FormElemWrapperComponent>
                                    <TextFieldComponent 
                                        label="Codul" 
                                        variant="outlined" 
                                        fullWidth={true}
                                        value={state.fields.code.value}
                                        error={state.shouldDisplayError && !state.fields.code.isValid}
                                        helperText={state.shouldDisplayError && !state.fields.code.isValid && state.fields.code.errorMessage}
                                        onTextChange={e => updateState('code', e)}
                                    />
                                </FormElemWrapperComponent>

                                <FormElemWrapperComponent>
                                    <TextFieldComponent 
                                        label="Noua parola" 
                                        variant="outlined" 
                                        type="password"
                                        fullWidth={true}
                                        value={state.fields.newPassword.value}
                                        error={state.shouldDisplayError && !state.fields.newPassword.isValid}
                                        helperText={state.shouldDisplayError && !state.fields.newPassword.isValid && state.fields.newPassword.errorMessage}
                                        onTextChange={e => updateState('newPassword', e)}
                                    />
                                </FormElemWrapperComponent>

                                <FormElemWrapperComponent>
                                    <ButtonComponent variant="text">
                                        Retrimite codul
                                    </ButtonComponent>
                                </FormElemWrapperComponent>

                            </> :
                            null
                        }
                        
                    </div>

                    <div className="bottom-area">
                        <FormElemWrapperComponent>
                            <ButtonComponent fullWidth={true} onClick={onButtonClick} isLoading={isLoading}>
                                {buttonLabelByPhase[phase]}
                            </ButtonComponent>
                        </FormElemWrapperComponent>
                    </div>
                </>
            }

            

        </ForgotPasswordPageStyled>
    )

}

export default ForgotPasswordPage;