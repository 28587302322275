import {DashboardPageStyled} from "./dashboard.page.styled";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import Box from '@mui/material/Box';
import DatePickerComponent from '../../../../components/date-picker/date-picker.component';
import OverallStatsComponent, { RangeDateTypeProps } from '../../../../components/overall-stats/overall-stats.component';
import TelephoneExchangeComponent from '../../../../components/telephone-exchange/telephone-exchange.component';
import ClientStatsComponent from '../../../../components/client-stats/client-stats.component';
import OrderStatsComponent from '../../../../components/order-stats/order-stats.component';
import TabComponent from '../../../../components/tab/tab.component';
import React from "react";
import { RoleEnum } from "../../../../enums/role.enum";

const DashboardPage = () => {

    const [valueOne, setValueOne] = React.useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [valueTwo, setValueTwo] = React.useState<Date>(new Date());
    const [tabIndex, setTabIndex] = React.useState<number>(0);

    const rangeDate: RangeDateTypeProps = React.useMemo (
        () => {
            return {
                startDate: valueOne,
                endDate: valueTwo
            }
        },
        [valueOne, valueTwo]
    )

    const tabs = React.useMemo(
        () => {
            return [
                {
                    headerTab: {
                        label:"Overall",
                    },
                    panel: () => (
                        <OverallStatsComponent tabIndex={tabIndex}  rangeDate={rangeDate} />
                    )

                },
                {
                    headerTab: {
                        label:"Centrala telefonica",
                    },
                    roles: [RoleEnum.Admin],
                    panel: () => (
                            <TelephoneExchangeComponent tabIndex={tabIndex} rangeDate={rangeDate} />
                    )
                },
                {   
                    headerTab: {
                        label:"Clienti",
                    },
                    roles: [RoleEnum.Admin],
                    panel: () => (
                        <ClientStatsComponent tabIndex={tabIndex} rangeDate={rangeDate} />
                    )
                    
                },
                {
                    headerTab: {
                        label:"Comenzi",
                    },
                    roles: [RoleEnum.Admin],
                    panel: () => (
                        <OrderStatsComponent tabIndex={tabIndex} rangeDate={rangeDate} />
                    )
                },
            ]        
        },
        [rangeDate, tabIndex]
    )

    const onChangeTab = React.useCallback(
        (newValue: number) => {
            setTabIndex(newValue)
        },
        []
    )

    /** define the return statement bellow */
    return (
        <DashboardPageStyled>
            <PageTemplateComponent title="Dashboard">
                <Box 
                    sx={{
                        display: 'flex', 
                        justifyContent: 'flex-start', 
                        marginTop:'1rem', 
                        marginBottom: '2rem'
                    }}
                >
                    <DatePickerComponent  
                        value={valueOne} 
                        setValue={setValueOne} 
                        label="Data inceput"
                    />
                    <span 
                        style={{
                            display: 'flex', 
                            alignItems: 'center', 
                            marginLeft: '0.5rem', 
                            marginRight: '0.5rem'
                        }}
                    > - 
                    </span>
                    <DatePickerComponent 
                        value={valueTwo} 
                        setValue={setValueTwo} 
                        label="Data final"
                    />
                </Box>
                <TabComponent  
                    tabs={tabs} 
                    onTabChange={onChangeTab}
                />
            </PageTemplateComponent>    
        </DashboardPageStyled>
    )

}

export default DashboardPage;