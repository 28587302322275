import { Chip, Paper } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ProfilePageStyled from "./profile.page.styled";
import TabComponent, { TabElementType } from '../../../../components/tab/tab.component';
import { GroupAdd, GroupRemove, Settings } from "@mui/icons-material";
import AvatarComponent from "../../../../components/avatar/avatar.component";
import { getUser } from '../../../../utils/get-user.util';
import backgroundSrc from '../../../../assets/images/profile_background.png';
import { PROFILE_AVATAR_SIZE } from "../../../../utils/constants";
import ProfileGeneralDetailsComponent from "../../../../components/profile-general-details/profile-general-details.component";
import { useStore } from '../../../../hooks/store.hook';
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { UpdateSelfType, UpdateUserType, UserType } from "../../../../types/user.type";
import { getRequest, putRequest } from "../../../../utils/http-requests";
import ButtonComponent from "../../../../components/button/button.component";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import toast from "react-hot-toast";


const ProfilePage = observer(() => {

    /** inject the user store into the component */
    const userStore = useStore('userStore');


    /** inject route parameters */
    let params = useParams();

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [userDetails, setUserDetails] = useState<UserType | null>(null);
    const [updateUserLoading, setUpdateUserLoading] = useState(false);

    useEffect(
        () => {
            if (params.userId) {
                getRequest({ url: `/user/${params.userId}` })
                    .then(({data}) => {
                        setUserDetails(() => data);
                    })
                    .catch((e: any) => toast.error(e.message));
            } else {
                setUserDetails(() => userStore.user);
            }
        },
        [params, userStore.user]
    )

    useEffect(
        () => {
            if(userDetails)
                userStore.setUser(userDetails)
        },
        [
            userDetails, 
            userStore
        ]
    )
    
    const updateUserDetails = useCallback(
        async (updatedDetails: UpdateSelfType | UpdateUserType) => {
            setUpdateUserLoading(() => true);
            try {
                if ((updatedDetails as UpdateUserType).roles && userDetails?.id) {
                    await putRequest({ url: `/user/${userDetails?.id}`, body: updatedDetails });
                    setUserDetails(user => ({
                        ...user,
                        ...(updatedDetails as UpdateUserType),
                        fullName: `${updatedDetails.firstName} ${updatedDetails.lastName}`
                    } as UserType | null));
                } else {
                    await putRequest({ url: '/user', body: updatedDetails });

                    setUserDetails(user => ({
                        ...user,
                        ...(updatedDetails as UpdateSelfType),
                        fullName: `${updatedDetails.firstName} ${updatedDetails.lastName}`
                    } as UserType | null));
                }

                userStore.setUser({
                    ...userStore.user,
                    ...(updatedDetails as UpdateUserType),
                    fullName: `${updatedDetails.firstName} ${updatedDetails.lastName}`
                } as UserType);

            } catch(e: any) {
                toast.error(e.message);
            } finally {
                setUpdateUserLoading(() => false);
            }

        },
        [userStore, userDetails]
    )

    const tabs = useMemo(
        (): TabElementType[] => {
            return [
                {
                    headerTab: {
                        label: 'Setari generale',
                        icon: <Settings />
                    },
                    panel: (
                        () => (
                            <>
                                <ProfileGeneralDetailsComponent 
                                    user={userDetails}
                                    isSelf={!params.userId}
                                    onSave={updateUserDetails}
                                    isLoading={updateUserLoading}
                                />
                            </>
                        )
                    )
                  
                }
            ]
        },
        [userDetails, params, updateUserDetails, updateUserLoading]
    );

    const getAuthorizedTabs = useCallback(
        (): TabElementType[] => {
            const user = getUser();
            if (!user) return [];

            return tabs.filter((tab) => (tab.roles ?? []).every(role => user.roles.includes(role)) );
        },
        [tabs]
    )

    const onTabChangeHandler = useCallback(
        (newTabIndex: number) => {
            setSelectedTabIndex(newTabIndex);
        },
        []
    )

    const toggleAccountActivation = useCallback(
        async () => {
            const userId = params.userId ?? userStore.user?.id;

            try {
                await putRequest({ url: `/user/toggle-activation/${userId}` });
                
                if (params.userId) {
                    setUserDetails(currentUserDetails => ({
                        ...currentUserDetails,
                        isActive: !currentUserDetails?.isActive
                    } as UserType | null));
                } else {
                    userStore.setUser({ 
                        ...userStore.user, 
                        isActive: !userStore.user?.isActive 
                    } as UserType);
                }

            } catch(e: any) {
                toast.error(e.message);
            }
        },
        [userStore, params]
    )


    /** define the render logic bellow */
    return (
        <ProfilePageStyled>
            <PageTemplateComponent title={ !params.userId ? 'Contul tau' : 'Profil' }>
         
                <Paper className="profile-pannel" elevation={2}>
                    <div className="banner-top">

                        <div className="background-wrapper">
                            <img src={backgroundSrc} alt="Profile background" />
                        </div>
                        <div className="user-section">
                            <AvatarComponent name={userDetails?.fullName ?? ''} size={`${PROFILE_AVATAR_SIZE}rem`} />
                            <div className="user-description">
                                <div className="user-name">
                                    {userDetails?.fullName ?? ''}
                                </div>

                                <div className="user-account-status">
                                    { 
                                        userDetails?.isActive ? 
                                        <Chip label="Activ" color="success" size="medium" /> :
                                        <Chip label="Inactiv" color="error" size="medium"  />
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="banner-bottom">
                        <TabComponent tabs={getAuthorizedTabs()} noPanels onTabChange={onTabChangeHandler} />
                        <div className="toggle-status-button">
                            {
                                params.userId ?
                                <ButtonComponent
                                    variant="text"  
                                    onClick={toggleAccountActivation}
                                    aria-haspopup="true"
                                    size="medium"
                                    startIcon={ userDetails?.isActive ? <GroupRemove /> : <GroupAdd /> }
                                >
                                    { userDetails?.isActive ? 'Dezactiveaza contul' : 'Activeaza contul' }
                                </ButtonComponent> :
                                ''
                            }
                            
                        </div>
                    </div>
                    
                </Paper>

                <div className="tab-panel-wrapper">
                    {
                        tabs[selectedTabIndex].panel?.()
                    }
                </div>

            </PageTemplateComponent>
        </ProfilePageStyled>
    )

})

export default ProfilePage;