import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Divider, Paper } from '@mui/material';
import { themeVariables } from '../../theme/variables';
import { ShowDataCardPropsType } from '../client-stats/client-stats.component';
import { isArray, isEmpty } from 'lodash';
import NoDataComponent from '../no-data/no-data.component';
import { CurrencyEnum } from '../../enums/currency.enum';
import { formatNumber } from '../../utils/utils';

export type VirtualizedListPropsType = {
    details: ShowDataCardPropsType
    styleList?: {
        width: number,
        height: number
    },
    styleBox?: {
        width: string,
        height: string
    },
    currency?: boolean
}

export default function VirtualizedList({
    details,
    styleList,
    styleBox,
    currency
}: VirtualizedListPropsType) {

    const isArr: boolean | undefined = React.useMemo(
        () => (details.value ? isArray(details.value) : undefined),
        [details]
    )

    const isSimpleArr: boolean = React.useMemo(
        () => (isArr && typeof (details.value[0]) === 'object' ? true : false),
        [isArr, details.value]
    )

    const renderListItemTextPrimary = React.useCallback(
        (index: number, val: any, valObj?: any) => {
            return (
                <ListItemText
                    disableTypography={true}
                    sx={{
                        color: 'black',
                        fontWeight: themeVariables.fontWeights.medium,
                        fontSize: themeVariables.fontSizes.medium
                    }}
                    primary={isArr ?
                        <div>
                            {val}
                        </div>
                        :
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingRight: '1rem'
                        }}>
                            <div> {val} </div>
                            <div> 
                                {
                                    currency ?
                                        `${formatNumber(valObj)} ${CurrencyEnum.RON}`
                                        :
                                        valObj
                                }
                            </div>
                        </div>
                    }
                />
            )
        }, 
        [isArr, currency]
    )

    const renderListItemTextSecondary = React.useCallback(
        (val: any) => {
            return (
                <ListItemText
                    disableTypography={true}
                    sx={{
                        fontWeight: themeVariables.fontWeights.bold,
                        fontSize: themeVariables.fontSizes.subtitle
                    }}
                    secondary={val}
                />
            )
        },
        []
    )

    const renderSimpleArrRow = React.useCallback(
        (props: ListChildComponentProps) => {
            const { index, style } = props;

            return (
                <ListItem style={style} key={index} component="div" disablePadding>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        {renderListItemTextPrimary(index, details.value[index])}
                    </div>
                </ListItem>
            )
        },
        [details, renderListItemTextPrimary]
    )

    const renderObjectRow = React.useCallback(
        (props: ListChildComponentProps) => {
            const { index, style } = props;

            return (
                <ListItem style={style} key={index} component="div" disablePadding>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        {renderListItemTextPrimary(index, Object.keys(details.value)[index], Object.values(details.value)[index])}
                    </div>
                </ListItem>
            );
        },
        [details, renderListItemTextPrimary]
    )

    const renderArrayOfObjectsRow = React.useCallback(
        (props: ListChildComponentProps) => {
            const { index, style } = props;

            return (
                <ListItem style={style} key={index} component="div" disablePadding>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        {
                            (Object.values(details.value[index]) as any).map(
                                (m: any, i: number) => (
                                    <> {i === 0 ? renderListItemTextPrimary(index, m) : renderListItemTextSecondary(m)} </>
                                ))}
                    </div>
                </ListItem>
            );
        },
        [details, renderListItemTextPrimary, renderListItemTextSecondary]
    )

    // const heightList = React.useMemo (
    //     () => {
    //         if( isArr === true ){
    //             if( isSimpleArr === true )
    //                 if(details.value.length * Object.keys(details.value[0]).length * 40 < 280)
    //                     return details.value.length * Object.keys(details.value[0]).length * 40

    //             if( isSimpleArr === false )
    //                 if(details.value.length * 60 < 280)
    //                     return details.value.length * 60
    //         }

    //         if( isArr === false )
    //             if(Object.keys(details.value).length * 60 < 280)
    //                 return Object.keys(details.value).length * 60

    //         return 280
    //     },
    //     [isSimpleArr, details, isArr]
    // )

    return (
            <Paper
                elevation={6}
                sx={{
                    width: styleBox?.width ?? '18.75rem', 
                    height: styleBox?.height ?? '18.75rem', 
                    padding: '2rem', 
                    borderRadius: '1rem',
                    margin: '0.5rem',
                    boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
                    paddingBottom: '3rem'

                }}
            >
                <h2 style={{  marginTop: '0.5rem',
                        marginBottom: '0.5rem',
                        fontWeight: themeVariables.fontWeights.semibold,
                        fontSize: themeVariables.fontSizes.medium }}
                    >{details.label}
                </h2>
                <Divider />
                {
                    isArr !== undefined && !isEmpty(details.value) ?
                        <FixedSizeList
                            height={styleList?.height ?? 300}
                            width={styleList?.width ?? 300}
                            itemSize={
                                isArr ?
                                    isSimpleArr ? Object.keys(details.value[0]).length * 40 : 60
                                    :
                                    60
                            }
                            itemCount={isArr ? details.value.length : Object.keys(details.value).length}
                            overscanCount={5}
                        >
                            {
                                isArr ?
                                    isSimpleArr ? renderArrayOfObjectsRow : renderSimpleArrRow
                                    :
                                    renderObjectRow
                            }
                        </FixedSizeList>
                        :
                        <NoDataComponent />
                }   
                
            </Paper>        
    )
}   
