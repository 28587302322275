import { Chip } from "@mui/material";
import React, { useMemo } from "react";
import { OrderStatusEnum } from "../../enums/order-status.enum";

export type OrderStatusBadgeComponentPropsType = {
    status: OrderStatusEnum;
}

const OrderStatusBadgeComponent = ({
    status
}: OrderStatusBadgeComponentPropsType) => {

    const colorsDict = useMemo(
        () => {
            return {
                [OrderStatusEnum.Draft]: {
                    color: 'rgba(0, 0, 0, 0.87)',
                    background: 'rgba(0, 0, 0, 0.08)'
                },
                [OrderStatusEnum.Canceled]: {
                    color: 'rgb(183, 33, 54)',
                    background: 'rgba(255, 72, 66, 0.16)'
                },
                [OrderStatusEnum.Cashed]: {
                    color: 'rgb(34, 154, 22)',
                    background: 'rgba(84, 214, 44, 0.16)'
                },
                [OrderStatusEnum.Issued]: {
                    color: 'rgb(12, 83, 183)',
                    background: 'rgba(24, 144, 255, 0.16)'
                },
                [OrderStatusEnum.AwbGenerated]: {
                    color: '#e0730d',
                    background: '#fbecdd'
                },
                [OrderStatusEnum.Returned]: {
                    color: 'rgb(183, 129, 3)',
                    background: 'rgba(255, 193, 7, 0.16)'
                }
            }
        },
        []
    );


    /** define the returns statement bellow */
    return (
        <Chip 
            size="small" 
            label={status}
            style={{
                color: colorsDict[status].color,
                background: colorsDict[status].background
            }}
        />
    )

}

export default OrderStatusBadgeComponent;