import styled from "styled-components";


export const TitlePieChartStyled = styled.div`
    font-size: 1.125rem;
    font-weight: bold;
    padding: 1rem
`

export const SubTitlePieChartStyled = styled.div`
`

export const CenterChart = styled.div`
    display: flex; 
    align-items: center; 
    flex-basis: 100%;
    
`

export const PieChartStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center; 
    height: 100%;
`
