import React from 'react';
import OrderDetailsHeaderComponentStyled from './order-details-header.component.styled';

export type OrderDetailsHeaderComponentPropsType = {
    items: { component: React.ReactNode, canDisplay: any }[]
}

const OrderDetailsHeaderComponent = ({
    items
}: OrderDetailsHeaderComponentPropsType) => {

    /** define the return statement */
    return (
        <OrderDetailsHeaderComponentStyled>
            <div className="left-section">
                {items.map((item, index) => (
                    <div key={index}>
                        {item.component}
                    </div>
                ))}
            </div>
        </OrderDetailsHeaderComponentStyled>
    )

}

export default OrderDetailsHeaderComponent;