import { isEmpty } from "lodash";
import { makeAutoObservable, observable } from "mobx";
import React from "react";
import { DialogResponseTypeEnum } from "../enums/dialog-response-type.enum";
import { PanelType } from "../enums/panel-type.enum";
import { RoleEnum } from "../enums/role.enum";
import { RootStore } from "./root.store";

export type PanelActionItem = {
    component: React.ReactNode
}

export type UiStorePanelType = {
    key: PanelType;
    component: React.ReactNode;
    title?: string;
    additionalTitle?: React.ReactNode;
    onDismiss?: (data: any) => void;
    panelWidth?: string;
    actionItems?: PanelActionItem[];
    tabIndex?: number;
    tabs?: {
        label: string;
        roles?: RoleEnum[] | undefined;
        icon?: React.ReactNode;
    }[],
    onTabChange?: (newValue: number) => void;
    subtitleComponent?: () => React.ReactNode;
    isLoading?: boolean;
}

export type ModalActionItem = {
    icon: React.ReactNode,
    tooltipText: string;
    onClick: () => void
};

export type UiStoreModalType = {
    component: React.ReactNode;
    title?: string;
    fullScreen?: boolean;
    actionItems?: ModalActionItem[];
}

export enum PanelActionEnum {
    Push,
    Pop
} 

export type PersistentToastType = {
    message: string;
    action: ((response: 'confirm' | 'dismiss') => void)
}

export type UiStoreDialogType = {
    title: string;
    message: string;
    withReason?: boolean;
}

export type DismissPanelPropsType = {
    dismissAll?: boolean;
    data?: any;
}

export type DialogResponseType = {
    value: DialogResponseTypeEnum,
    reason?: string;
}

export class UiStore {

    private _rootStore: RootStore;

    dialogActive: boolean = false;
    dialogResFunction: ((response: DialogResponseType) => void) | null = null;
    currentDialog: UiStoreDialogType | null = null;
    sideNavbarExtended: boolean = false;
    panelExtended: boolean = false;
    openedPanels: UiStorePanelType[] = [];
    lastPanelAction: PanelActionEnum | null = null;
    persistentToastVisible: boolean = false;
    currentPersistentToast: PersistentToastType | null = null;
    modalOpen: boolean = false;
    currentModal: UiStoreModalType | null = null;
    orderData: boolean = false;

    constructor(rootStore: RootStore) {
        this._rootStore = rootStore;
        makeAutoObservable(this, {
            openedPanels: observable.ref,
            currentModal: observable.ref
        });
    }

    toggleNavbarState() {
        this.sideNavbarExtended = !this.sideNavbarExtended;
    }

    openPanel(props: UiStorePanelType) {

        /** hide the scroll bar behind the panel */
        // document.body.style.overflow = "hidden"; 

        this.panelExtended = true;
        this.lastPanelAction = PanelActionEnum.Push;
        this.openedPanels = [...this.openedPanels, props];

    }

    dismissPanel(dismissProps?: DismissPanelPropsType) {
        let onDismiss = this.openedPanels[this.openedPanels.length-1].onDismiss;

        if (dismissProps?.dismissAll) {
            this.lastPanelAction = null;
            this.openedPanels = [];
        }
        else {
            this.lastPanelAction = PanelActionEnum.Pop;
            this.openedPanels = this.openedPanels.slice(0, this.openedPanels.length-1);
        }

        if (isEmpty(this.openedPanels)) {
            document.body.style.overflow = "auto";
            this.panelExtended = false;
        }

        onDismiss?.(dismissProps?.data);
    }

    updatePanel(panelOptions: Partial<Omit<UiStorePanelType, 'key' | 'component'>>) {
        if (isEmpty(this.openedPanels)) return;
        // const user = getUser();
        // if (!user) return [];
        // panelOptions.tabs = panelOptions.tabs?.filter((tab) => (tab.roles ?? []).every(role => user.roles.includes(role)) );
    
        this.openedPanels = [
            ...this.openedPanels.slice(0, this.openedPanels.length-1),
            {
                ...this.openedPanels[this.openedPanels.length-1],
                ...panelOptions
            }
        ];
    }

    updateModal(modalOptions: Partial<Omit<UiStoreModalType, 'component'>>) {
        if (!this.currentModal) return;

        this.currentModal = {
            ...this.currentModal,
            ...modalOptions
        }
    }

    openModal(modalProps: UiStoreModalType) {
        /** hide the scroll bar behind the panel */
        document.body.style.overflow = "hidden";

        this.modalOpen = true;
        this.currentModal = modalProps;

    }

    dismissModal() {

        /** hide the scroll bar behind the panel */
        document.body.style.overflow = "auto";
        this.modalOpen = false;
        this.currentModal = null;

    }

    showPersistentToast(props: PersistentToastType) {
        this.persistentToastVisible = true;
        this.currentPersistentToast = props;
    }

    dismissPersistentToast() {

        this.persistentToastVisible = false;
        this.currentPersistentToast = null;

    }

    openDialog(props: UiStoreDialogType): Promise<DialogResponseType>{
        /** hide the scroll bar behind the panel */
        // document.body.style.overflow = "hidden"; 
        this.dialogActive = true;
        this.currentDialog = props;

        this.dialogResFunction = () => {}
        return new Promise<DialogResponseType> (
            (resolve) => {
                this.dialogResFunction = resolve
            }
        )
    }

    dissmissDialog(){
        document.body.style.overflow = "auto"; 
        this.dialogActive = false;
        this.currentDialog = null;
    }

    setOrderData(data: boolean){
        if(data)
            this.orderData = true
        else
            this.orderData = false
    }

    resetOrderData(){
        this.orderData = false
    }

}