import styled from "styled-components";
import { themeVariables } from "../../theme/variables";

const OrderDetailsComponentStyled = styled.div`

    .product-name {
        color: gray;
        font-size: ${themeVariables.fontSizes.smaller};
    }

    .order-details-section {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .no-assigned-user {
            color: gray;
            font-size: ${themeVariables.fontSizes.subtitle};
        }
    }

    .invoice-section {
        display: flex;
        align-items: stretch;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;

        .marker-area {
            background: ${themeVariables.colors.primary};
            width: 6px;
            border-radius: 10px;
            margin-right: 0.8rem;

            &.red {
                background: ${themeVariables.semanticColors.error};
            }
        }

        .content-area {
            flex: 1;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .invoice-title {
                font-weight: ${themeVariables.fontWeights.semibold};
            }

            .invoice-description {
                color: gray;
                font-size: ${themeVariables.fontSizes.smaller};
            }

        }

        .button-area {
            display: flex;
            align-items: center;
            margin-left: 1rem;
        }
    }

`;

export default OrderDetailsComponentStyled;