import { AwbTypeEnum } from "../enums/awb-type.enum";
import { BillingAddressType, ShippingAddressType } from "../types/address.type";
import { LogEntityEnum } from "../enums/log-entity.enum";
import { CreateCustomerType, CustomerRangeDateReqType, CustomerType, UpdateCustomerType } from "../types/customer.type"
import { LogType } from "../types/log.type";
import { CentralRangeDateReq, CreateOrderType, GenerateAwbVm, OrderRangeDateReqType, OrderType, OverallRangeDateReq, SelectedProductsType, UpdateOrderPaymentType, UpdateOrderType } from "../types/order.type";
import { BulkStockUpdateType, CreateProductType, ProductType, StockLinesType, UpdateProductType } from "../types/product.type";
import { SettingsCompanyType, SettingsType } from '../types/settings.type';
import { UpdateUserType, UserType } from "../types/user.type";
import { deleteRequest, formDataPostRequest, getRequest, postRequest, putRequest } from "./http-requests"
import moment from "moment";
import { ContentTypeEnum } from "../enums/content-type.enum";
import { RangeDateTypeProps } from "../components/overall-stats/overall-stats.component";
import { LogTypeCustomer } from "../components/customer-details-history-tab/customer-details-history-tab.component";
import { ProductsType } from "../components/products-export.panel/products-export.panel.component";

export const getCustomer = async (customerId: string): Promise<CustomerType> => {
    const { data } = await getRequest({
        url: `/customer/${customerId}`
    });
    return data as CustomerType;
}

export const createCustomer = async (data: CreateCustomerType): Promise<void> => {
    await postRequest({
        url: '/customer/create-customer',
        body: data
    });
}

export const updateCustomer = async (customerId: string, data: UpdateCustomerType): Promise<void> => {
    await putRequest({
        url: `/customer/${customerId}`,
        body: data,
    });
}

export const updateShippingAddress = async (customerId: string, reqData: ShippingAddressType): Promise<string> => {
    const { data } = await putRequest({
        url: `/address/shipping/${customerId}`,
        body: reqData,
    });

    return data as string;
}

export const deleteShippingAddress = async (customerId: string, addressId: string): Promise<void> => {
    await deleteRequest({
        url: `/address/shipping/${customerId}/${addressId}`
    });
}

export const updateBillingAddress = async (customerId: string, reqData: BillingAddressType): Promise<string> => {
    const { data } = await putRequest({
        url: `/address/billing/${customerId}`,
        body: reqData,
    });

    return data as string;
}

export const deleteBillingAddress = async (customerId: string, addressId: string): Promise<void> => {
    await deleteRequest({
        url: `/address/billing/${customerId}/${addressId}`
    });
}

export const deleteCustomer = async (customerId: string): Promise<void> => {
    await deleteRequest({
        url: `/customer/${customerId}`
    });
}

export const getUser = async (userId: string): Promise<UserType> => {
    const { data } = await getRequest({
        url: `/user/${userId}`
    })
    return data as UserType;
}

export const createUser = async (data: UpdateUserType): Promise<void> => {
    await postRequest({
        url: `/user/create-user`,
        body: data,
    });
}

export const updateUser = async (userId: string, data: UpdateUserType): Promise<void> => {
    await putRequest({
        url: `/user/${userId}`,
        body: data,
    });
}

export const toggleUserActivation = async (userId: string): Promise<void> => {
    await putRequest({
        url: `/user/toggle-activation/${userId}`
    });
}

export const getProduct = async (productId: string): Promise<ProductType> => {
    const { data } = await getRequest({
        url: `/product/${productId}`
    })
    return data as ProductType;
}

export const updateProduct = async (productId: string, data: UpdateProductType): Promise<void> => {
    await putRequest({
        url: `/product/${productId}`,
        body: data,
    });
}

export const createProduct = async (data: CreateProductType): Promise<void> => {
    await postRequest({
        url: '/product',
        body: data,
    });
}

export const bulkStockUpdate = async (data: BulkStockUpdateType): Promise<void> => {
    await putRequest({
        url: '/product/bulk-stock-update',
        body: data,
    });
}

export const getOrder = async (orderId: string): Promise<OrderType> => {
    const { data } = await getRequest({
        url: `/order/${orderId}`
    })
    return data as OrderType;
}

export const issueOrder = async (orderId: string): Promise<OrderType> => {
    const { data } = await postRequest({
        url: `/order/issue/${orderId}`
    });
    return data as OrderType;
}

export const cancelOrder = async (orderId: string): Promise<OrderType> => {
    const { data } = await postRequest({
        url: `/order/cancel/${orderId}`
    });
    return data as OrderType;
}

export const createOrder = async (data: Partial<CreateOrderType>): Promise<void> => {
    await postRequest({
        url: '/order/create-order',
        body: data,
    });
}

export const getSelectedProducts = async (requestData: SelectedProductsType): Promise<ProductType[]> => {
    const { data } = await postRequest({
        url: '/product/get-selected-product',
        body: requestData,
    })
    return data as ProductType[];
}

export const generateAwb = async (awbType: AwbTypeEnum, orderId: string, requestData: GenerateAwbVm): Promise<OrderType> => {
    const { data } = await postRequest({
        url: `/order/generate-awb/${awbType}/${orderId}`,
        body: requestData,
    });
    return data as OrderType;
}

export const downloadAwb = async (awbType: AwbTypeEnum, orderId: string): Promise<any> => {

    const response = await getRequest({
        url: `/order/download-awb/${awbType}/${orderId}`,
        otherSettings: {
            responseType: 'blob'
        }
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });

    return URL.createObjectURL(blob);

}

export const getSettings = async (): Promise<SettingsType> => {
    const { data } = await getRequest({
        url: '/settings'
    });
    return data as SettingsType;
}

export const updateCompanyDetails = async (requestData: SettingsCompanyType): Promise<SettingsType> => {
    const { data } = await putRequest({
        url: '/settings/update-company-details',
        body: requestData
    });
    return data as SettingsType;
}

export const getAnaf = async (cui: string): Promise<any> => {
    const data = await getRequest({
        url: `/anaf`,
        queryParams: {
            cui: cui
        }
    })
    return data
}

export const updateOrder = async (orderId: string, requestData: Partial<UpdateOrderType>): Promise<OrderType> => {
    const { data } = await putRequest({
        url: `/order/${orderId}`,
        body: requestData
    });

    return data;
}
export const customerRangeDateReq = async (rangeDate: RangeDateTypeProps): Promise<CustomerRangeDateReqType> => {
    const { data } = await getRequest({
        url: '/customer/range-request',
        queryParams: {
            rangeDate: rangeDate
        }
    })

    return data
}



export const assignOrderUser = async (orderId: string, userId?: string): Promise<OrderType> => {
    const { data } = await putRequest({
        url: `/order/assign-user/${orderId}`,
        body: {
            userId: userId
        }
    });

    return data;
}

export const updateStockLine = async (productId: string, data: StockLinesType, deleteSL: boolean): Promise<void> => {
    await putRequest({
        url: `/product/stockline/${productId}`,
        body: data,
        queryParams: {
            deleteSL: deleteSL
        }
    });
}

export const getLogs = async (entity: LogEntityEnum, entityId: string, startDate?: Date, endDate?: Date): Promise<LogType> => {
    const { data } = await getRequest({
        url: `/log/${entity}/${entityId}`,
        queryParams: {
            startDate: startDate,
            endDate: endDate
        }
    })

    return data;
}

export const getCustomersLogs = async (customerId: string, startDate?: Date, endDate?: Date): Promise<LogTypeCustomer> => {
    const { data } = await getRequest({
        url: `/log/${customerId}`,
        queryParams: {
            startDate: startDate,
            endDate: endDate
        }
    })

    return data;
}

export const updatePayment = async (orderId: string, requestData: UpdateOrderPaymentType): Promise<OrderType> => {
    const { data } = await putRequest({
        url: `/order/payment/${orderId}`,
        body: requestData
    });

    return data;
}

export const downloadCustomersImportTemplate = async(): Promise<void> => {
    const response = await getRequest({
        url: `/customer/download-import-template`,
        otherSettings: {
            responseType: 'blob'
        }
    })

    const blob = new Blob([response.data], { type: ContentTypeEnum.Xlsx });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Raport clienti - ${moment(new Date()).format('DD.MM.YYYY HH.mm')}.xlsx`); //or any other extension
    link.click();
    window.URL.revokeObjectURL(url)
}

export const uploadCustomerImport = async(file: File): Promise<void> => {
    await formDataPostRequest({
        url: '/customer/upload-import',
        body: {
            file: file
        }
    })
}
export const orderRangeDateReq = async (rangeDate: RangeDateTypeProps): Promise<OrderRangeDateReqType> => {
    const { data } = await getRequest({
        url: '/order/range-request',
        queryParams: {
            rangeDate: rangeDate
        }
    })

    return data
}

export const overallRangeDateReq = async (rangeDate: RangeDateTypeProps): Promise<OverallRangeDateReq> => {
    const { data } = await getRequest({
        url: '/order/overall/range-request',
        queryParams: {
            rangeDate: rangeDate
        }
    })

    return data
}

export const centralRangeDateReq = async (rangeDate: RangeDateTypeProps): Promise<CentralRangeDateReq> => {
    const { data } = await getRequest({
        url: '/order/central/range-request',
        queryParams: {
            rangeDate: rangeDate
        }
    })

    return data
}

export const downloadProductsExportTemplate = async(startDate: Date, endDate: Date): Promise<void> => {
    const response = await getRequest({
        url: `/product/download-export-template`,
        queryParams: {
            startDate: startDate,
            endDate: endDate
        },
        otherSettings: {
            responseType: 'blob'
        }
    })

    const blob = new Blob([response.data], { type: ContentTypeEnum.Xlsx });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Raport produse ${moment(new Date(startDate)).format('DD.MM.YYYY HH.mm')} - ${moment(new Date(endDate)).format('DD.MM.YYYY HH.mm')}.xlsx`); //or any other extension
    link.click();
    window.URL.revokeObjectURL(url)
}

export const getTimeForExportTemplate = async (startDate?: Date, endDate?: Date): Promise<ProductsType> => {
    const { data } = await getRequest({
        url: `/product/get-time`,
        queryParams: {
            startDate: startDate,
            endDate: endDate
        }
    })

    return data;
}