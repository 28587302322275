import React from 'react'
import FrameViewerComponentStyled from "./frame-viewer.component.styled";

export type FrameViewerComponentPropsType = {
    url?: string;
}

const FrameViewerComponent = ({
    url,
}: FrameViewerComponentPropsType) => {

    /** define the return statement bellow */
    return (
        <>
            {
                url ?
                    <FrameViewerComponentStyled 
                        src={url}
                    /> :
                    ''
            }
        </>
        
    )

}

export default FrameViewerComponent;