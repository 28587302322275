export enum PanelType {
    AddUser,
    Customer,
    EditUser,
    AddProduct,
    AddOrder,
    AddAddress,
    EditAddress,
    ViewOrder,
    AssignAwb,
    EditOrderPayment,
    OrderAssignUser,
    ProductStockLine,
    EditOrder,
    ViewClient,
    ViewClientHistory,
    ImportCustomer,
    ExportProducts
}