import React, { useMemo } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { FooterPropsType } from '../table/table.component';
import { themeVariables } from '../../theme/variables';
import { CurrencyEnum } from '../../enums/currency.enum';
import { Divider } from '@mui/material';
import { formatNumber } from '../../utils/utils';

export type OrderTotalFooterComponentPropsType = {
    discountTotal?: number;
    total: number;
    withDivider?: boolean;
} & FooterPropsType;

const OrderTotalFooterComponent = ({
    numberOfCells,
    discountTotal = 0,
    total,
    withDivider = false,
    withActionItems
}: OrderTotalFooterComponentPropsType) => {

    const cellStyles = useMemo(
        () => {
            return {
                fontSize: themeVariables.fontSizes.normal,
                color: themeVariables.textColors.primary,
                fontWeight: themeVariables.fontWeights.semibold,
            }
        },
        []
    )

    const discountCellStyles = useMemo(
        () => {
            return {
                fontSize: themeVariables.fontSizes.subtitle,
                color: 'gray',
                fontWeight: themeVariables.fontWeights.regular,
                paddingTop: '0.1rem',
                paddingBottom: '0.1rem'
            }
        },
        []
    )

    const subtotalCellStyles = useMemo(
        () => {
            return {
                fontSize: themeVariables.fontSizes.subtitle,
                color: 'gray',
                fontWeight: themeVariables.fontWeights.regular,
                paddingTop: '1rem',
                paddingBottom: '0.1rem'
            }
        },
        []
    )

    const noOfCells = useMemo(
        () => {
            if (withActionItems)
                return numberOfCells-1;
            return numberOfCells;
        },
        [withActionItems, numberOfCells]
    )

    /** define the return statement bellow */
    return (
        <>

            {
                withDivider ?
                    <TableRow>
                        <TableCell colSpan={numberOfCells}>
                            <Divider />
                        </TableCell>
                    </TableRow> :
                    ''
            }

            {
                discountTotal ?
                    <>
                        <TableRow>
                            {
                                new Array(Math.max(noOfCells-2, 0)).fill(0).map((_, index) => (
                                    <TableCell key={index}></TableCell>
                                ))
                            }
                            <TableCell align="right" style={subtotalCellStyles}>
                                Subtotal
                            </TableCell>
                            <TableCell align="right" style={subtotalCellStyles}>
                                {total} {CurrencyEnum.RON}
                            </TableCell>
                            {
                                withActionItems ?
                                <TableCell></TableCell> :
                                ''
                            }
                        </TableRow>

                        <TableRow>
                            {
                                new Array(Math.max(noOfCells-2, 0)).fill(0).map((_, index) => (
                                    <TableCell key={index}></TableCell>
                                ))
                            }
                            <TableCell align="right" style={discountCellStyles}>
                                Discount
                            </TableCell>
                            <TableCell align="right" style={discountCellStyles}>
                                {discountTotal} {CurrencyEnum.RON}
                            </TableCell>
                            {
                                withActionItems ?
                                <TableCell></TableCell> :
                                ''
                            }
                        </TableRow>
                    </>
                     :
                    ''
            }

            <TableRow>
                {
                    new Array(Math.max(noOfCells-2, 0)).fill(0).map((_, index) => (
                        <TableCell key={index}></TableCell>
                    ))
                }
                <TableCell align="right" style={cellStyles}>
                    Total
                </TableCell>
                <TableCell align="right" style={cellStyles}>
                    {formatNumber(total - discountTotal)} {CurrencyEnum.RON}
                </TableCell>
                {
                    withActionItems ?
                    <TableCell></TableCell> :
                    ''
                }
            </TableRow>

        </>
        
    )

}

export default OrderTotalFooterComponent;